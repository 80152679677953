import React, {useEffect, useState} from 'react';
import UserService from '../Services/UserService';
import './Substudent.css';
import SubstudentService from '../Services/SubstudentService';


const Substudentedit = () => {
    const [getUsers, setGetUsers] = useState(null);
    let num = -1;
    let substudentKey = -1;
    let [arrayPos,setArrayPos] = useState(0);
    // let [arrayPosUser,setArrayPosUser] = useState(1);

    //set substudent
    const [substudents,setSubstudents] = useState({
        id: "", 
        user: 0,
        substudent: 0,
        dateofbirth: "",
        lessontime: "",
        lessoncode: "",
        additionalinfo: "",
        totallessons: "",
        currentstage: ""
    });

        //onchange for the form
    const onChange = e => {
        setSubstudents({...substudents, 
            [e.target.name]: e.target.value, 
            

        });
        console.log("substudents onchange",substudents);

        
    }

    const onChangeGetPos = e => {
        const selectedIndex = e.target.options.selectedIndex;
        console.log('index', e.target.options[selectedIndex].getAttribute('data-index'));

        setArrayPos(substudents.substudent = e.target.options[selectedIndex].getAttribute('data-index'));
        // console.log('arraypos2', arrayPos)

        // console.log(substudents);
    }

    const onSubmit = e =>{
        e.preventDefault();
        SubstudentService.update(substudents).then(data=>{
        });
        console.log('substudents', substudents);
        

        setSubstudents({
            id: "",
            user: 0,
            substudent: "",
            dateofbirth: "",
            lessoncode: "",
            lessontime: "",
            additionalinfo: "",
            totallessons: "",
            currentstage: ""

        });
    }

    useEffect(()=>{
        SubstudentService.getSubstudent().then(data=> {
            // getUserFunction(data.users)
            console.log('data', data.substudent)
            // console.log(data.users[arrayPos].substudent[arrayPos].dob);
               
        });        
    });

    let getUserFunction = (data) => {
        setGetUsers(data);
    }

    if(!getUsers) {
        return "no data";
    }
    return(
        <div className="substudent-container middle">
            <div className="edit-form">
                <h1>Edit Form</h1>

                <form onSubmit={onSubmit}>

            
            
 
 <br />
<br />

<label htmlFor="substudent">Substudent:</label> <br />

            <select
                name="substudent"
                id="substudent"
                type="text"
                // value={substudents.substudent}
                onChange={onChangeGetPos}
                required
            >
                
                <option value="" hidden>Choose here</option>

                {
                    getUsers[substudents.user].substudent.map((student)=>{
                        substudentKey++;
                        return (
                        <option key={substudentKey} data-index={substudentKey} value={substudentKey}>{substudentKey}: {(student.name)}</option>
                        )
                    })
                }
            </select>


            <br />
            <label htmlFor="totallessons">Total Lessons:</label> <br />
            <input 
                name="totallessons"
                id="totallessons"
                type="text"
                value={substudents.totallessons}
                onChange={onChange} 
                required
                disabled
            />
            <br />
{/* 
            <label htmlFor="currentstage">Current Stage:</label> <br />
            <input 
                name="currentstage"
                id="currentstage"
                type="text"
                value={substudents.currentstage}
                onChange={onChange} 
                required
                disabled
            />
            <br />
            <label htmlFor="dateofbirth">Date of Birth:</label> <br />
            <input 
                name="dateofbirth"
                id="dateofbirth"
                type="text"
                value={substudents.dateofbirth}
                onChange={onChange} 
                required
            />
            <br />

            <label htmlFor="lessontime">Lesson Time:</label> <br />

            <input 
                name="lessontime"
                id="lessontime"
                type="text"
                value={substudents.lessontime}
                onChange={onChange} 
                required
            />


            <br />
            <br />
            <label htmlFor="lessoncode">Lesson Code</label> <br />
            <input 
                name="lessoncode"
                id="lessoncode"
                type="text"

                value={substudents.lessoncode}
                onChange={onChange} 
                required
            />
            <br />
       
            <br />
            <label htmlFor="additional-info">Additional Info:</label> <br />
            <textarea 
            cols="50"
            rows="4"
            name="additionalinfo"
            id="additionalinfo"
            type="text"

            value={substudents.additionalinfo}
            onChange={onChange}
            required 
            >
            </textarea>
            
            <br />  */}
            <button type="submit">Edit Substudent</button> 

            </form>

                {/* list of students under parents account */}

            {
                    getUsers[substudents.user].substudent.map((student)=>{
                        num++;
                        return (
                        <div>{(student.name)}</div>
                        )
                    })
                }
            </div>
            {
                console.log(getUsers[substudents.user].substudent[substudents.substudent])
            }
            {/* {setValues()} */}
            
            {console.log("substudent final", substudents)}
        </div>
    )
}

export default Substudentedit;