import React from 'react';
import './Resources.css';
import book1 from './book1.pdf';
import book2 from './book2.pdf';

const Presources = () => {

    return (
        <div className="resources-container">
        <h1> Access Resources here</h1>
        <h2> Books </h2>
    <div className="row">
        <div className="book">

            {/* <a href={"/pbook1"} target="_blank" rel="noopener noreferrer" >View book online</a> */}
            <a href={"/pbook1"} target="_blank" rel="noopener noreferrer"><img className="book-img" src="/img/resources/book.png" /></a>
            <a href={book1} download> Click here to Download Book </a>

            <p className="center-text">Book 1 takes the student from zero to be able to read and write the Arabic language.</p>


        </div>
        <div className="book">
            {/* <a href={"/pbook2"} target="_blank" rel="noopener noreferrer">Book 2</a> */}
            <a href={"/pbook2"} target="_blank" rel="noopener noreferrer"><img className="book-img" src="/img/resources/book.png" /></a>
            <a href={book2} download> Click here to Download Book </a>
            <p className="center-text">Book 2 focuses on some of the specific rules to reading the Qur'aan.</p>
        </div>
    </div>

    {/* <div className="row">
        <div className="book">

            <a href={"/pbook1"} target="_blank" rel="noopener noreferrer" >Book 1</a>
            <br />
            <a href={"/pbook1"} target="_blank" rel="noopener noreferrer"><img className="book-img" src="/img/resources/book.png" /></a>
            <p className="center-text">Book 1 takes the student from zero to be able to read and write the Arabic language.</p>

        </div>
    </div> */}

    {/* <iframe title="Qur'aan" className="vh" src={'https://epub.qurancomplex.gov.sa/issues/hafs/standard39/'} width="100%" height="800px" frameborder="0"></iframe> */}


</div>
    )
}

export default Presources;