import React, {useState,useEffect} from 'react';
import LessonreportService from '../Services/LessonreportService';
import TeacherService from '../Services/TeacherService';
// import Message from './Message';
// import { AuthContext } from '../Context/AuthContext';

// import {QuraanVerses} from './data';
import {Stages} from './data';

import {
    Button,
    useToast, 
  } from "@chakra-ui/react";

const Lessonreportedit = () =>{

    const toast = useToast();

    //display reports from get request
    // const [message,setMessage] = useState(null);

//date
//teacher
//name
//lessonnumber
//currentstage

//homework
//_id
const [reports,setReports] = useState({id: "", date: "", teacher: "", name: "", lessonnumber: "", currentstage: "", description:"", homework: ""});
const [getTeachers, setGetTeachers] = useState(null) 

// let teacherCounter = -1;
let [arrayPos,setArrayPos] = useState(0);
let [arrayElements,setArrayElements] = useState(null);
// let num = 0;

    const onChange = e => {
        setReports({...reports, [e.target.date]: e.target.value, [e.target.teacher]: e.target.value, [e.target.name]: e.target.value, [e.target.lessonnumber]: e.target.value, [e.target.currentstage]: e.target.value, 
            [e.target.description]: e.target.value,
            [e.target.homework]: e.target.value});
        console.log(reports);
    }

    // const authContext = useContext(AuthContext);

    useEffect(()=>{
        LessonreportService.getLessonreport().then(data =>{
            numberOfRecords(data.lessonreport.length);
            // console.log('length of array',data.lessonreport.length)
            // console.log('data',data)
            // console.log('data.lessonreport', data.lessonreport[arrayPos]);
                setReports({
                    date: data.lessonreport[arrayPos].date,
                    teacher: data.lessonreport[arrayPos].teacher,
                    name: data.lessonreport[arrayPos].name,
                    lessonnumber: data.lessonreport[arrayPos].lessonnumber,
                    currentstage: data.lessonreport[arrayPos].currentstage,
                    homework: data.lessonreport[arrayPos].homework,
                    description: data.lessonreport[arrayPos].description,
                    id: data.lessonreport[arrayPos]._id,
                });
            
            
        });

        TeacherService.getTeacher().then(data => {
            console.log('data.teacher',data.teacher);
            gettingTeachers(data.teacher)
        })
    },[setArrayPos, arrayPos]);

    let gettingTeachers = (data) => {
        setGetTeachers(data);
        // console.log('gettingteachersfunction', data[0]._id);
    }
    const onSubmit = e =>{
        e.preventDefault();
        LessonreportService.update(reports).then(data=>{
        });

        toast({
            title: `Thank you.`,
            description: `Lesson report for student edited`,
            status: "success",
            duration: 7000,
            isClosable: true,
          })
    }

    var previousRecord = () => {
        if(arrayPos > 0) {
            arrayPos = arrayPos - 1
            setArrayPos(arrayPos)
        }
    }

    var nextRecord = () => {
        if(arrayPos < arrayElements) {
            arrayPos = arrayPos + 1
            setArrayPos(arrayPos)
        }
    }

    var numberOfRecords = (arrayLen) => {
        setArrayElements(arrayLen-1);
    }

    if (!getTeachers) {
        return <div>No data</div>;
    }
    if (!reports) {
        return <div>No data</div>;
    }
    return(
        <div className="middle">
            {reports.id ? null : "Curently no data exists"}
        <form onSubmit={onSubmit}>
            <label htmlFor="reports-date">Date: </label><br />
            <input 
                name="date"
                id="reports-date"
                type="text"
                value={reports.date}
                onChange={onChange}
            />
             <br />
             <br />

<br />
            <label htmlFor="reports-teacher">Teacher:</label> <br />
            <input
                name="teacher"
                id="reports-teacher"
                type="text"
                value={reports.teacher}
                onChange={onChange} 
                disabled
            />
<br />
            <br />
            <label htmlFor="reports-name">Student:</label> <br />
            <input
                name="name"
                id="reports-name"
                type="text"
                value={reports.name}
                onChange={onChange} 
                disabled
            />
            <br />
            <label htmlFor="reports-lessonnumber">Lesson Number:</label> <br />
            <input 
                name="lessonnumber"
                id="reports-lessonnumber"
                type="text"
                value={reports.lessonnumber}
                onChange={onChange} 
            />
            <br />
            <label htmlFor="reports-currentstage">Current Stage:</label> <br />
            <select name="currentstage"
                id="reports-currentstage"
                type="text"
                value={reports.currentstage}
                onChange={onChange} 
                required
                >
                <option hidden>Choose here</option>
                    {
                        Stages.map((stage)=>{
                        return (<option label={stage.stage}>{stage.value}</option>)
                        })
                    }
                
            </select>
            {/* <input     
                name="currentstage"
                id="reports-currentstage"
                type="text"
                value={reports.currentstage}
                onChange={onChange}  
            /> */}

            <br />
            <label htmlFor="reports-homework">Description:</label> <br />
            <textarea 
            cols="35"
            rows="4"
             name="description"
             id="reports-description"
             type="text"
             value={reports.description}
             onChange={onChange} 
            >
            </textarea>
            <br />
            <label htmlFor="reports-homework">Homework:</label> <br />
            <textarea 
            cols="35"
            rows="4"
            name="homework"
            id="reports-homework"
            type="text"
            value={reports.homework}
            onChange={onChange} 
            >
            </textarea>
            
            <br /> 
            <Button type="submit" 
                    leftIcon="add" 
                    variantColor="teal"
            >
                        Update Report
            </Button>
            {/* <button type="submit">Update Report</button>  */}
        </form>



     <h1>Bottom {arrayPos}</h1>
     <button onClick={()=>previousRecord()}>Previous</button>
     <button onClick={()=>nextRecord()}>Next</button>
     arrayPos {arrayPos}
        
    </div>
);

}

export default Lessonreportedit;

