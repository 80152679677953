import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import AuthService from '../Services/AuthService';
import { AuthContext } from '../Context/AuthContext';
import './Navbar.css'

const Navbar = props =>{
    const {isAuthenticated,user,setIsAuthenticated,setUser} = useContext(AuthContext);
    // console.log(user);
       

    const onChangeHandler = e => {
        console.log('log', e.target.value);
        if(e.target.value == "logout") {
           return onClickLogoutHandler();
        }
            window.location.assign("/" + e.target.value);  
    }
    
    const onClickLogoutHandler = ()=>{
        AuthService.logout().then(data=>{
            if(data.success){
                setUser(data.user);
                setIsAuthenticated(false);
            }
        });
    }

    const unauthenticatedNavBar = ()=>{
        return (
            <>
                {/* <Link to="/">
                    <li className="nav-item nav-link">
                        Home
                    </li>
                </Link>   */} 

                <Link to="/login">
                    <li className="nav-item nav-link">
                        Login
                    </li>
                </Link>  
                <Link to="/register">
                    <li className="nav-item nav-link">
                        Register
                    </li>
                </Link>
                <Link to="/pquraan/1/1/7">
                    <li className="nav-item nav-link hide-me">
                        Qur'an
                    </li>
                </Link>
                <Link to="/prwarabic">
                    <li className="nav-item nav-link hide-me">
                        Read and Write Arabic
                    </li>
                </Link>                
                <Link to="/presources">
                    <li className="nav-item nav-link hide-me">
                        Resources
                    </li>
                </Link> 
                <div className="right-nav right-nav-links">
                    <Link to="/demo">
                        <li className="hide-me">
                            Demo
                        </li>
                    </Link> 

                    <Link to="/tutor">
                        <li className="hide-me">
                            Tutor
                        </li>
                    </Link> 
                    <Link to="/About">
                        <li className="hide-me">
                            About
                        </li>
                    </Link> 
                    <Link to="/Policy">
                        <li className="hide-me">
                            Policy
                        </li>
                    </Link> 
                </div>
                
             
             
            </>
        )
    }

    const unauthenticatedNavBarMobile = ()=>{
        return (
            <>
                <select 
                        className="mobile-select"
                        onChange={onChangeHandler}
                >
                    <option hidden>Select page</option>
                    <option value="login">Login</option>
                    <option value="register">Register</option>
                    <option value="pquraan/1/1/7">Qur'aan</option>
                    <option value="prwarabic">Read and write Arabic</option>
                    <option value="presources">Resources</option>
                    <option value="demo">Demo</option>
                    <option value="tutor"> Tutor </option>
                    <option value="about"> About </option>
                    <option value="policy"> Policy </option> 
                </select>
             
            </>
        )
    }

    const authenticatedNavBar = ()=>{
        return(
            <>
                {
                    user.role === "user" || user.role === "teacher" ? 
                    

                    <>
               <Link to="/quraan/1/1/7">
                    <li className="nav-item nav-link">
                        Qur'an
                    </li>
                </Link>
                <Link to="/rwarabic">
                    <li className="nav-item nav-link">
                        Read and Write Arabic
                    </li>
                </Link>
                {/* <Link to="/">
                    <li className="nav-item nav-link">
                        Home
                    </li>
                </Link>  */}
                {/* <Link to="/todos">
                    <li className="nav-item nav-link">
                        Todos
                    </li>
                </Link>  */}
                <Link to="/profile">
                    <li className="nav-item nav-link">
                        Profile
                    </li>
                </Link>                 
                
                {/* <Link to="/live">
                    <li className="nav-item nav-link">
                        Live
                    </li>
                </Link>                */}
                
                <Link to="/transactions">
                    <li className="nav-item nav-link">
                        My Transactions
                    </li>
                </Link>
                <Link to="/lessonreport">
                    <li className="nav-item nav-link">
                        My Reports
                    </li>
                </Link> 
                <Link to="/resources">
                    <li className="nav-item nav-link">
                        Resources
                    </li>
                </Link>
                </>
             :null }
                                
                    {/* //teacher links */}

                {/* {
                    user.role === "teacher" ? 
                    <Link to="/teachermylessons">
                    <li className="nav-item nav-link">
                        teachermylessons
                    </li>
                    </Link> : null
                } */}
{
                    user.role === "teacher" ? <>
                    <Link to="/teachereditmyreports">
                    <li className="nav-item nav-link">
                        Edit Reports
                    </li>
                    </Link> 
                    
              
                    <Link to="/lessonreportcreate">
                    <li className="nav-item nav-link">
                        Create Reports
                    </li>
                    </Link>                

                    <Link to="/mystudentsedit">
                        <li className="nav-item nav-link">
                            Edit Students
                        </li>
                    </Link> </>
                
                    :null
                
                }

                    {/* //admin links */}

                {
                    user.role === "admin" ? 
                    <Link to="/admin">
                        <li className="nav-item nav-link">
                            Admin
                        </li>
                    </Link> : null
                }  

                {
                    user.role === "admin" ? 
                    <Link to="/credit">
                    <li className="nav-item nav-link">
                        Credit
                    </li>
                    </Link> : null
                } 

                {/* {
                    user.role === "admin" ? 
                    <Link to="/lessonreportcreate">
                    <li className="nav-item nav-link">
                    Lesson report Create
                    </li>
                    </Link> : null
                } */}
                 {/* {
                    user.role === "admin" ? 
                    <Link to="/lessonreportedit">
                    <li className="nav-item nav-link">
                        Lesson Report Edit
                    </li>
                    </Link> : null
                }    */}
  {
                    user.role === "admin" ? 
                    <Link to="/transactionsall">
                    <li className="nav-item nav-link">
                        All Transactions
                    </li>
                    </Link> : null
                }   

                {
                    user.role === "admin" ? 
                    <Link to="/teachers">
                    <li className="nav-item nav-link">
                        Teachers
                    </li>
                    </Link> : null
                }

                {
                    user.role === "admin" ? 
                    <Link to="/parentedit">
                    <li className="nav-item nav-link">
                        Parent Edit
                    </li>
                    </Link> : null
                }

                {
                    user.role === "admin" ? 
                    <Link to="/substudent">
                    <li className="nav-item nav-link">
                        Substudent Edit
                    </li>
                    </Link> : null
                }
                {
                    user.role === "admin" ? 
                    <Link to="/lessonreportsall">
                    <li className="nav-item nav-link">
                        All Lesson Reports
                    </li>
                    </Link> : null
                }

                {
                    user.role === "admin" ? 
                    <Link to="/substudentadd">
                    <li className="nav-item nav-link">
                        Substudent Add
                    </li>
                    </Link> : null
                }

                <button type="button" 
                        className="btn btn-link nav-item nav-link" 
                        onClick={onClickLogoutHandler}>Logout</button>
            </>
        )
    }

  
    return(
        <div>
            <nav className="navbar">
                <Link to="/">
                    <img className="logo" src='img/home/logo.png' alt="logo"></img>
                </Link>
                <div>
                    <ul>
                        { !isAuthenticated ? unauthenticatedNavBar() : authenticatedNavBar()}
                    </ul>
                </div>
            </nav>

            <nav className="navbar-mobile">
                <Link to="/">
                    <img className="logo-mob" src='img/home/logo.png' alt="logo"></img>
                </Link>
                <div>
                    <ul>
                        {  !isAuthenticated ? unauthenticatedNavBarMobile() : <select 
                        className="mobile-select"
                        onChange={onChangeHandler}
                >
                    <option hidden>Select page</option>

                    <option value="quraan/1/1/7">Qur'aan</option>
                    <option value="rwarabic">Read and write Arabic</option>
                    <option value="profile">Profile</option>
                    <option value="transactions">My Transactions</option>
                    <option value="lessonreport"> My Reports </option>
                    <option value="policy"> Policy </option>
                    <option value="logout"> Logout </option>
                   
                    {user.role == "teacher" ? <> <option value="lessonreportcreate">Create Report</option>
                    <option value="teachereditmyreports">Edit Report</option>
                    <option value="mystudentsedit">Edit My Students</option> </>
                     : null }

                    {user.role == "admin" ? <> <option value="substudent">Substudent Edit</option>
                    <option value="substudentadd">Substudent Add</option>
                    <option value="parentedit">View Users</option> </>
                     : null }
                    
                </select>}
                        
                    </ul>
                </div>
            </nav>
            
            
            
        </div>
    )
}

export default Navbar;