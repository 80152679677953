export default {
    getLessonreport : ()=>{
        return fetch('/user/lessonreport')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },
    getLessonreportsAll : ()=>{
        return fetch('/user/lessonreportsall')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },
    //lesson report to be passed back to be saved in DB
    update : reports =>{
        console.log(reports);
        return fetch('/user/lessonreport',{
            method : "put",
            body : JSON.stringify(reports),
            headers : {
                'Content-Type' : 'application/json'
            }
        }).then(res => res.json())
          .then(data => data);
    },

    postReport : reports => {
        return fetch('/user/lessonreport',{
            method : "post",
            body : JSON.stringify(reports),
            headers:{
                'Content-Type' : 'application/json'
            }
        }).then(response=>{
            if(response.status !== 401){
                return response.json().then(data => data);
            }
            else
                return {message : {msgBody : "UnAuthorized"}, msgError : true};
        });
    },
}