import React from 'react';
import './Quraan.css'

const Live = () => {

    let url = window.location.pathname;
    let student = url.split("/");
    let surah = student[student.length - 3];
    let ayahfrom = student[student.length - 2];
    let ayahto = student[student.length - 1];  
       
    return (
        <div>
            <iframe title="quraan" className="quraan" src={'https://en.muqri.com/#alhusari-t,'+surah+','+ayahfrom+','+ayahto+',1,3,0x'} frameborder="0">
            </iframe>
        </div>
        )
}
export default Live;