import React, {useEffect, useState} from 'react';
import UserService from '../Services/UserService';
import SubstudentService from '../Services/SubstudentService';

import './Substudent.css';


const Substudentadd = () => {
    const [getUsers, setGetUsers] = useState(null);
    let num = -1;

    //set substudent
    const [substudents,setSubstudents] = useState({
        id: "", 
        user: 0,
        name: "",
        dob: "",
        lessontime: "",
        lessoncode: "",
        additionalinfo: ""
    });

        //onchange for the form
    const onChange = e => {
        setSubstudents({...substudents, 
            [e.target.user]: e.target.value,
            [e.target.name]: e.target.value, 
            // [e.target.substudent]: e.target.value, 
            [e.target.dateofbirth]: e.target.value, 
            [e.target.lessontime]: e.target.value, 
            [e.target.lessoncode]: e.target.value, 
            [e.target.additionalinfo]: e.target.value});
        // console.log('user pos', substudents.user);
        substudents.id = getUsers[substudents.user];
    }

    const onSubmit = e =>{
        e.preventDefault();
        SubstudentService.postSubstudent(substudents).then(data=>{
        });
        setSubstudents({
            id: "",
            user: 0,
            name: "",
            dob: "",
            lessoncode: "",
            lessontime: "",
            additionalinfo: ""
        });
    }

    useEffect(()=>{
        UserService.getUsers().then(data=> {
            getUserFunction(data.users)
        });
        
    },[]);

    let getUserFunction = (data) => {
        setGetUsers(data);
    }

    if(!getUsers) {
        return "no data";
    }
    return(
        <div className="substudent-container middle">
            <div className="edit-form">
                <h1>Add Substudent</h1>

                <form onSubmit={onSubmit}>

            
            
 
 <br />
 <label htmlFor="user">User:</label> <br />

            <select
                name="user"
                id="user"
                type="text"
                value={substudents.user}
                onChange={onChange}
                required
            >
                  <option hidden>Choose here</option>

                {
                    getUsers.map((user)=>{
                        num++;
                        return (user.role === "user" ? <option key={num} value={num}>{num}: {(user.username)}</option> : null
                        )
                    })
                }
            </select>         
                
<br />

<label htmlFor="name">Substudent:</label> <br />

            <input 
                name="name"
                id="name"
                type="text"
                value={substudents.name}
                onChange={onChange} 
                required
            />


            <br />
            <label htmlFor="dob">Date of Birth:</label> <br />
            <input 
                name="dob"
                id="dob"
                type="text"
                value={substudents.dob}
                onChange={onChange} 
                required
            />
            <br />

            <label htmlFor="lessontime">Lesson Time:</label> <br />

            <input 
                name="lessontime"
                id="lessontime"
                type="text"
                value={substudents.lessontime}
                onChange={onChange} 
                required
            />


            <br />

            <br />
            <label htmlFor="lessoncode">Lesson Code</label> <br />
            <input 
                name="lessoncode"
                id="lessoncode"
                type="text"
                value={substudents.lessoncode}
                onChange={onChange} 
                required
            />
            <br />
       
            <br />
            <label htmlFor="additional-info">Additional Info:</label> <br />
            <textarea 
            cols="50"
            rows="4"
            name="additionalinfo"
            id="additionalinfo"
            type="text"

            value={substudents.additionalinfo}
            onChange={onChange}
            required 
            >
            </textarea>
            
            <br /> 
            <button type="submit">Add Substudent</button> 

            </form>
            {substudents.id=getUsers[substudents.user]._id}

                {/* list of students under parents account */}

            {
                    getUsers[substudents.user].substudent.map((student, index)=>{
                        num++;
                        return (
                        <div key={index}>{student.name} [{student.dob}] [{student.lessoncode}] [{student.lessontime}] - {student.additionalinfo}</div>
                        )
                    })
                }
            </div>
           
        </div>
    )
}

export default Substudentadd;