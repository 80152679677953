import React from 'react';
import './Home.css';



const Home = ()=> {

    return(
        <div className="home-container">
            <div className="intro col-parent">
                <div className="grey-font col-child">
                    <h1>Learn to read the Qur'aan with personalised tuition</h1>
                    <h3> Interactive one-to-one lessons </h3>
                    <h3> Practice online at anytime </h3>
                    <h3> Easy to follow coursebook </h3>
                </div>
                <div className="col-child">
                    <img className="img" src="/img/home/cover-laptop.png" alt="product-shot"/>
                </div>                
            </div>
            <h3 className="grey-font">SCROLL DOWN</h3>

            <div className="grey-font  how-it-works">
            <h1 className="center">How it works</h1>

                <div className="col-parent">
                    <div className="col-child">
                        <h3>Log in for your live one-to-one session</h3>
                        <p>We have designed interactive lessons to keep learners
                           engaged. We do this by varying the activities without
                           losing the important repetition element of learning
                           the Qur’aan.
                        </p>
                    </div>
                    <div className="col-child">
                        <img className="img" src="/img/home/product-shot-1.png" alt="product-shot" />
                    </div>
                </div>
                <div className="col-parent">
                    <div className="col-child">
                        <h3>Access our online system anytime</h3>
                        <p>
                            We have carefully designed interactive online content which can be accessed at any time. This allows our learners to reinforce what they learn in their live sessions so that they can progress faster.
                        </p>
                    </div>
                    <div className="col-child">
                        <img className="img" src="/img/home/product-shot-2.png" alt="product-shot" />
                    </div>
                    
                </div>
                <div className="col-parent">
                    <div className="col-child">
                        <h3>Follow along with our specially designed course book</h3>
                        <p>
                            We have created a course book which ties
                            everything together. The book serves as a physical
                            resource and guide through the course.
                        </p>
                    </div>
                    <div className="col-child">
                        <img className="img" src="/img/home/product-shot-2.png" alt="product-shot" />
                    </div>
                </div>
            </div>

            {/* //GET STARTED */}

            <div className="get-started">
                
                    <h1>Get Started</h1>
                    <p>It couldn't be easier to get started on your journey!</p>
                
                    <div className="grey-font items">

                        <div className="item center-col center">
                            <img className="get-started-icons" src="/img/home/register-icon.png" alt="product-shot" />
                            <h5>Register</h5>
                            <p>Create an account</p>
                        </div>

                        <div className="item center-col center">
                            <img className="get-started-icons" src="/img/home/pay-select-icon.png" alt="product-shot" />
                            <h5>Pay and Select a Time</h5>
                            <p>Make an online payment and select a convenient time for your lessons</p>
                        </div>

                        <div className="item center-col center">
                            <img className="get-started-icons" src="/img/home/lesson-icon.png" alt="product-shot" />
                            <h5>Log in for your lesson</h5>
                            <p>Log in and launch our virtual classroom</p>
                        </div>                        
                    </div>
                
            </div>

            <h1>Reviews</h1>
            <div className="reviews">
                Reviews
            </div>

        </div>
    )
}
    


export default Home;