import React, {useState, useEffect} from 'react';
import TeacherService from '../Services/TeacherService';
import SubstudentService from '../Services/SubstudentService';

const Teachers = () => {

    const [getTeachers, setGetTeachers] = useState(null) 
    const [getSubstudents, setGetSubstudents] = useState(null) 
    let teacherCounter = -1;
    let studentCounter = -1;
    let childCounter = -1;
    const [getCreateSubstudent, setCreateSubstudent] = useState({id_teacher: 0, id_substudent: 0})

    useEffect(()=> {
        TeacherService.getTeacher().then(data => {
            console.log('data',data.teacher);
            gettingTeachers(data.teacher)
        })

        SubstudentService.getSubstudent().then(data => {
            // console.log(data.substudent);
            gettingSubstudents(data.substudent)
        })
    }, [])


    let gettingTeachers = (data) => {
        setGetTeachers(data);
        console.log('get teachers', getTeachers);
        // console.log('gettingteachersfunction', data[0]._id);
    }
    let gettingSubstudents = (data) => {
        setGetSubstudents(data);
    }

    const onChange = e => {
        // console.log(getCreateSubstudent);

        setCreateSubstudent({...getCreateSubstudent, 
            [e.target.name]: e.target.value,
            [e.target.substudent_id]: e.target.value,
            [e.target.substudent]: e.target.value,
            [e.target.teacher]: e.target.value,
            [e.target.teacher_id]: e.target.value

        });
    }

    const onSubmit = e =>{
        e.preventDefault();
        if(getCreateSubstudent.id_teacher == 0) {
            console.log("please select a teacher");
        } else if (getCreateSubstudent.id_substudent == 0) {
            console.log("please select a student")
        } else {
            TeacherService.addStudent(getCreateSubstudent).then(data=>{
            });
            console.log('getCreateSubstudent', getCreateSubstudent);
            setCreateSubstudent({id_teacher: 0, id_substudent: 0});
        }

        // console.log("reports");

    }

    if(!getTeachers) {
        return "no data teachers"
    }
    if(!getSubstudents) {
        return "no data"
    }
    return(
        <div>
                        <form onSubmit={onSubmit}>
            <label htmlFor="id_teacher">Teacher:</label> <br />

                <select
                    name="id_teacher"
                    id="id_teacher"
                    type="text"
                    // value={getCreateSubstudent.teacher}
                    onChange={onChange} 
                    required
                >
                        <option value="" selected hidden>Choose here</option>
                {
                    getTeachers.map((teacher)=>{
                        teacherCounter++;
                        return (teacher.role === "teacher" ? <option key={teacherCounter} value={teacher._id}>                         
                            {teacherCounter}: {(teacher.username)}                      
                        </option> : null

                            
                            );
                    })
                }
                </select>
                <br />

                <label htmlFor="id_substudent">Substudent:</label> <br />
                <select
                name="id_substudent"
                id="id_"
                type="text"
                // value={getTeachers.substudent}
                onChange={onChange} 
                required
                >
                    
                    <option value="" selected hidden>Choose here</option>

                {

                    getSubstudents.map((substudent)=>{
                        studentCounter++;

                        return (
                            <option key={studentCounter} value={substudent._id}>
                                {studentCounter}: {(substudent.name)}
                            </option>                      
                        );
                    })
                }
                </select>
                <button>Add</button>
            </form>
            <h1>Teachers</h1>

            {
                    getTeachers.map((teacher)=>{
                        teacherCounter++;
                        return (
                            <option key={teacherCounter} value={teacher._id}>
                                {teacherCounter}: {(teacher.username)}: 

                                    {
                                        teacher.myStudent.map((child)=>{
                                            childCounter++;
                                            return [child.name + " | "]
                                        })
                                    }

                            </option>
                            );
                    })
                }
            <hr />
            <h1>
                Students
            </h1>
       
            {
                getSubstudents.map((substudent, index)=>{
                    return (
                        <div key={index}>
                            {substudent ? 
                                `${substudent.name} [${substudent._id}]`
                            : null}
                        </div>
                        );
                })
            }

            <hr />


            {console.log('getcreate', getCreateSubstudent)}

            

        </div>
    )
}
export default Teachers;