import React, {useEffect, useState} from 'react';
import UserService from '../Services/UserService';
import './Substudent.css';
import SubstudentService from '../Services/SubstudentService';
import { useToast } from "@chakra-ui/react"

const Substudent = () => {
    const [getSubstudents, setGetSubstudents] = useState(null);
    const toast = useToast();

    
    // const [substudents,setSubstudents] = useState(null);
    let num = -1;
    let substudentKey = -1;
    let [arrayPos,setArrayPos] = useState(0);

     // let [arrayPosUser,setArrayPosUser] = useState(1);

    //set substudent
    const [substudents,setSubstudents] = useState({
        id: "", 
        user: 0,
        substudent: 0,
        dateofbirth: "",
        lessontime: "",
        lessoncode: "",
        additionalinfo: "",
        totallessons: "",
        currentstage: ""
    });

        //onchange for the form
    const onChange = e => {
        setSubstudents({...substudents, 
            [e.target.user]: e.target.value,
            [e.target.name]: e.target.value, 
            [e.target.substudent]: e.target.value, 
            [e.target.dateofbirth]: e.target.value, 
            [e.target.lessontime]: e.target.value, 
            [e.target.lessoncode]: e.target.value, 
            [e.target.additionalinfo]: e.target.value,
            [e.target.totallessons]: e.target.value, 
            [e.target.currentstage]: e.target.value, 

        });
        console.log("substudents onchange",substudents);

        
    }

    const onChangeGetPos = e => {
        const selectedIndex = e.target.options.selectedIndex;
        console.log('index', e.target.options[selectedIndex].getAttribute('data-index'));

        setArrayPos(substudents.substudent = e.target.options[selectedIndex].getAttribute('data-index'));
    }

    const onSubmit = e =>{
        e.preventDefault();
        SubstudentService.update(substudents).then(data=>{
        });
        console.log('substudents', substudents);
        
        //pops up confirming update success
        toast({
            title: `Thank you`,
            description: `Student profile updated`,
            status: "success",
            duration: 7000,
            isClosable: true,
          })
    }

    useEffect(()=>{
        SubstudentService.getSubstudent().then(data=>{
            console.log("getMyStudents", data.substudent);
            getSubstudentsFunction(data.substudent);
            setSubstudents({
                user: 0,                
                id:  data.substudent[arrayPos]._id,
                substudent:  data.substudent[arrayPos].name,
                dateofbirth: data.substudent[arrayPos].dob,
                lessontime: data.substudent[arrayPos].lessontime,
                lessoncode: data.substudent[arrayPos].lessoncode,
                additionalinfo:  data.substudent[arrayPos].additionalinfo,
                totallessons: data.substudent[arrayPos].totallessons,
                currentstage: data.substudent[arrayPos].currentstage,
            });    
        })       
    },[arrayPos]);


    let getSubstudentsFunction = (data) => {
        setGetSubstudents(data);
    }

    if(!getSubstudents) {
        return "no data";
    }
    console.log("Substudents set", getSubstudents);

    return(
        <div className="substudent-container middle">
            <div className="edit-form">
                <h1>Edit Form</h1>

                <form onSubmit={onSubmit}>

 
 <br />
  <label htmlFor="user">My Students:</label> <br />

            <select
                name="user"
                id="user"
                type="text"
                // value={substudents.user}
                onChange={onChangeGetPos}
                required
            >
                {
                    getSubstudents.map((student)=>{
                        substudentKey++;
                        return (
                            <option key={substudentKey} data-index={substudentKey} value={substudentKey}>{substudentKey}: {(student.name)}</option>                        )
                    })
                }
            </select>         
                
            <br />
            <label htmlFor="totallessons">Total Lessons:</label> <br />
            <input 
                name="totallessons"
                id="totallessons"
                type="text"
                value={substudents.totallessons}
                onChange={onChange} 
                required
                disabled
            />
            <br />

            <label htmlFor="currentstage">Current Stage:</label> <br />
            <input 
                name="currentstage"
                id="currentstage"
                type="text"
                value={substudents.currentstage}
                onChange={onChange} 
                required
                disabled
            />
            <br />
            <label htmlFor="dateofbirth">Date of Birth:</label> <br />
            <input 
                name="dateofbirth"
                id="dateofbirth"
                type="text"
                value={substudents.dateofbirth}
                onChange={onChange} 
                required
                disabled

            />
            <br />

            <label htmlFor="lessontime">Lesson Time:</label> <br />

            <input 
                name="lessontime"
                id="lessontime"
                type="text"
                value={substudents.lessontime}
                onChange={onChange} 
                required
            />
            <br />
            <label htmlFor="lessoncode">Lesson Code</label> <br />
            <input 
                name="lessoncode"
                id="lessoncode"
                type="text"

                value={substudents.lessoncode}
                onChange={onChange} 
                required
            />
            <br />
            <label htmlFor="additional-info">Additional Info:</label> <br />
            <textarea 
            cols="50"
            rows="4"
            name="additionalinfo"
            id="additionalinfo"
            type="text"

            value={substudents.additionalinfo}
            onChange={onChange}
            required 
            >
            </textarea>
            
            <br /> 
            <button type="submit">Edit Substudent</button> 

            </form>

                {/* list of students under parents account */}

           
            </div>

            
        </div>
    )
}

export default Substudent;