import React, {useState, useEffect} from 'react';
import TransactionService from '../Services/TransactionService';
import UserService from '../Services/UserService';

const Credit = () => {
    const [transaction, setTransaction] = useState({
        id: "", credit: 0.00, debit: 0.00, descriptionTrans: ""
    });

    const [users, setUsers] = useState(null);

    var num = -1;
        const onSubmit = e =>{
            e.preventDefault();
            TransactionService.transaction(transaction).then(data=>{
            });

            setTransaction({descriptionTrans: ""})
            // console.log('substudents', substudents);
            // });

            console.log(transaction)
        }
    
    useEffect(()=>{
        UserService.getUsers().then(data=> {
            console.log('data.users', data.users)
            setUsers(data.users);
            
            // console.log(data.users[arrayPos].substudent[arrayPos].dob);
            // setSubstudents({
            //     user: 0,                
            //     id:  data.users[substudents.user].substudent[arrayPos]._id,
            //     substudent:  data.users[substudents.user].substudent[arrayPos].name,
            //     dateofbirth: data.users[substudents.user].substudent[arrayPos].dob,
            //     lessontime: data.users[substudents.user].substudent[arrayPos].lessontime,
            //     lessoncode: data.users[substudents.user].substudent[arrayPos].lessoncode,
            //     additionalinfo:  data.users[substudents.user].substudent[arrayPos].additionalinfo, 
            });    
        }, []);

        const onChange = e => {
            setTransaction({...transaction, 
                [e.target.name]: e.target.value,
            })
            // console.log("transaction", transaction);
            // console.log("users", users[transaction.user]);
            
        }
        // const onChangeGetPos = e => {
            // const selectedIndex = e.target.options.selectedIndex;
            // console.log('index', e.target.options[selectedIndex].getAttribute('data-index'));
    
            // setArrayPos(substudents.substudent = e.target.options[selectedIndex].getAttribute('data-index'));
            // console.log('arraypos2', arrayPos)
    
            // console.log(substudents);
        // }

        if(!transaction) {
            return "no data"
        }
        if(!users) {
            return "no data"
        }
        
    return(
        <div className="middle">
            <h1> Credit Page - </h1>
            select user
            Credit/Debit
            Amount 
            Description
            <form onSubmit={onSubmit}>

            
            
 
 <br />
 <label htmlFor="user">User:</label> <br /><br />

            <select
                name="id"
                id="id"
                type="text"
                // value={substudents.user}
                onChange={onChange}
                required
            >
                  <option value="" hidden>Choose here</option>

                {
                    users.map((user)=>{
                        num++;
                        return (
                        <option key={num} value={user._id}>{num}: {(user.username)}</option>
                        )
                    })
                }
            </select>         
                
<br />

<br />
            <label htmlFor="credit">Credit:</label> <br /><br />
            <input 
                name="credit"
                id="credit"
                type="number"
                step="any"
                value={transaction.credit}
                onChange={onChange} 
                required
            />
            <br />

            <br />
            <label htmlFor="debit">Debit:</label> <br /><br />

            <input 
                name="debit"
                id="debit"
                type="number"
                step="any"
                value={transaction.debit}
                onChange={onChange} 
                required
            />
            <br /><br />
            <label htmlFor="descriptionTrans">Description:</label> <br /><br />

            <textarea 
            cols="50"
            rows="4"
            name="descriptionTrans"
            id="descriptionTrans"
            type="text"

            value={transaction.descriptionTrans}
            onChange={onChange}
            required 
            >
            </textarea>

            <br /><br />
            <button type="submit">Update</button> 

            </form>

        </div>
    )
}

export default Credit;