import React, {useState} from 'react';
// import Lessonreportcreate from './Lessonreportcreate';
// import UserService from '../Services/UserService';
// import { AuthContext } from '../Context/AuthContext';
// import Profile from './Profile';
import './Rwarabic.css'


const Rwarabic = () => {
    // const {isAuthenticated,user,setIsAuthenticated,setUser} = useContext(AuthContext);
    
    let [lesson,setLesson] = useState({selected: "Stage1a"});

    // let url = window.location.pathname;
    // let currentstage = url.substring(url.lastIndexOf("S"), url.length-1);   
    // currentstage = currentstage.toLowerCase();
    

    const onChange = e => {
        setLesson({...lesson, 
            [e.target.name]: e.target.value,
        });
        console.log('lesson.selected', lesson.selected);
    }

    return (
        <div className="rw-container">
             {/* <label htmlFor="selected"></label> <br /> */}
            <select
                className="select-option"
                name="selected"
                id="selected"
                type="text"
                value={lesson.selected}
                onChange={onChange}

            >
                <option>Stage1a</option>
                <option>Stage1b</option>
                <option>Stage1c</option>
                <option>Stage2a</option>
                <option>Stage2b</option>
                <option>Stage3a</option>
                <option>Stage3b</option>
                <option>Stage3c</option>
                <option>Stage4a</option>
                <option>Stage4b</option>
                <option>Stage4c</option>
                <option>Stage5a</option>
                <option>Stage5b</option>
                <option>Stage5c</option>
                <option>Stage6a</option>
                <option>Stage6b</option>
                <option>Stage6c</option>
                <option>Stage6d</option>
                <option>Stage7a</option>
                <option>Stage7b</option>
                <option>Stage8</option>
                <option>Stage9</option>
                <option>Stage10</option>
                <option>Stage11</option>
            </select>

           <iframe title="online-activities" className="online-activities" src={'/Rwarabic/'+lesson.selected+'/story.html'}></iframe>
                          
        </div>
    )
}
export default Rwarabic;