import React from 'react';

const Tutor = () => {
    return(
        <div className="home-container">
            <h1>We are open to working with qualified tutors</h1>
            <p>If you're a qualified teacher that wants to use our platform to teach please get in touch. </p>
            <p>Phone/Whatsapp/Telegram: +44 7545 122 053</p>

        </div>
    )
}

export default Tutor;