import React from 'react';

const Demo = () => {
    return(
        <div className="home-container">
            <h1>Demo</h1>
            <p>At Qur'an Classroom we do things a little differently. We believe the traditional approach of repetition is vital in learning to read the Qur'an but we also believe using technology can be a means of making the process more efficient, particularly for children. We adopt a blended approach (part contact/part online) and try to make our lessons interactive and engaging. This means students don't just listen and repeat but are also required to actively participate in our lessons. We believe our methodology is great for those growing-up in the digital age. </p>
            <p>If you're interested, please get in touch so that we can arrange a free demo session with one of our team.</p>
            <p>Phone/Whatsapp/Telegram: +44 7545 122 053</p>
            <br />
            <p>Cost: £10 for a 25-30 minute session and access to the online content.</p> 
            <p> *We expect students to practice using our online system 2/3 times per week in addition to their time with a tutor.</p>

        </div>
    )
}

export default Demo;