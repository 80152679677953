import React, {useState,useEffect} from 'react';
import LessonreportService from '../Services/LessonreportService';
import SubstudentService from '../Services/SubstudentService';
import TeacherService from '../Services/TeacherService';

import './Lessonreport.css'


const Lessonreportsall = () =>{
    let substudentKey = 0;
    let [arrayPos,setArrayPos] = useState(0);
    let [searchName, setSearchName] = useState(null);
    let [teachers, setTeachers] = useState(null);
    const [getSubstudents, setGetSubstudents] = useState(null);


    const onChangeGetPos = e => {
        const selectedIndex = e.target.options.selectedIndex;
        // console.log('index', e.target.options[selectedIndex].getAttribute('data-index'));
        setArrayPos(e.target.options[selectedIndex].getAttribute('data-index'));
        setSearchName(e.target.options[selectedIndex].getAttribute('data-name'));
    }






    //----

    const [reports,setReports] = useState(null);
    useEffect(()=>{
        LessonreportService.getLessonreportsAll().then(data =>{
            setReports(data.lessonreport);
            // console.log('data.lessonreport', data.lessonreport);
        });


        //get all substudents
        SubstudentService.getSubstudent().then(data=>{
            // console.log("getMyStudents", data.substudent);
            getSubstudentsFunction(data.substudent);
               
        })       

        //get teachers
        TeacherService.getTeacherNames().then(data=>{
            // console.log("Teacher names", data.teacher);
            getTeachersFunction(data.teacher);
                
        })    
    },[arrayPos]);

    let getSubstudentsFunction = (data) => {
        setGetSubstudents(data);
    }  
    let getTeachersFunction = (data) => {
        setTeachers(data);
    }

    const transformDateTimeCreated = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.toUTCString();
    }

    if (!reports) {
        return <div>No data</div>;
    }
    if (!getSubstudents) {
        return <div>No data</div>;
    }
    if (!teachers) {
        return <div>No data</div>;
    }
    return(
        <div className="lesson-report-container">
      <h2> Lesson Reports</h2>

      <label htmlFor="user">Student Filter:</label> <br />

            <select
                name="user"
                id="user"
                type="text"
                // value={substudents.user}
                onChange={onChangeGetPos}
                required
            >
            <option hidden>Choose here</option>

                {
                    getSubstudents.map((student)=>{
                        substudentKey++;
                        return (
                            <option key={substudentKey} data-index={substudentKey} 
                            data-name={student.name}
                            value={substudentKey}> {(student.name)}</option>                        )
                    })
                }
            </select>

            <br />
            {/* {console.log("Teacher names", teachers)} */}

            <label htmlFor="user">Teacher Filter:</label> <br />

            <select
                name="user"
                id="user"
                type="text"
                // value={substudents.user}
                onChange={onChangeGetPos}
                required
            >
            <option hidden>Choose here</option>

                {
                    teachers.map((teacher)=>{
                        substudentKey++;
                        return (
                            <option key={substudentKey} data-index={substudentKey} 
                            data-name={teacher.username}
                            value={substudentKey}> {(teacher.username)}</option>                        )
                    })
                }
                <option>test</option>
            </select>


<table className="center">
      <thead>
        <tr>
          <th>Date</th>
          <th>Teacher</th>
          <th>Student</th>
          <th>Lesson Number</th>
          <th>Current Stage</th>
          <th>Description</th>
          <th>Homework</th>
        </tr>
      </thead>

      <tbody>

{/* {console.log("searchName", searchName)} */}
            
             

             {reports.map((report, index) => {
                return (
                    report.name == searchName ? 
                    <tr key={index}>
                        <td>{transformDateTimeCreated(report.date)}</td>
                        <td>{report.teacher}</td>
                        <td>{report.name}</td>
                        <td>{report.lessonnumber}</td>
                        <td>{report.currentstage}</td>
                        <td>{report.description}</td>
                        <td>{report.homework}</td>
                    </tr>
                    
                    : null 
                )
            })
             }

                {reports.map((report, index) => {
                return (
                    report.teacher == searchName ? 
                    <tr key={index}>
                        <td>{transformDateTimeCreated(report.date)}</td>
                        <td>{report.teacher}</td>
                        <td>{report.name}</td>
                        <td>{report.lessonnumber}</td>
                        <td>{report.currentstage}</td>
                        <td>{report.description}</td>
                        <td>{report.homework}</td>
                    </tr>
                    
                    : null 
                )
            })
             }

                       
                        
          </tbody>
        </table>              
 

        </div>
    );

}

export default Lessonreportsall;


