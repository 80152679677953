import React from 'react';
import './Resources.css'

const Resources = () => {

    return (
    <div className="resources-container">
            <h1> Access Resources here</h1>
            <h2> Books </h2>
        <div className="row">
            <div className="book">

                <a href={"/book1"} target="_blank" rel="noopener noreferrer" >Book 1</a>
                <br />
                <a href={"/book1"} target="_blank" rel="noopener noreferrer"><img className="book-img" src="/img/resources/book.png" /></a>
                <p className="center-text">Book 1 takes the student from zero to be able to read and write the Arabic language.</p>

            </div>
            <div className="book">
                <a href={"/book2"} target="_blank" rel="noopener noreferrer">Book 2</a>
                <br />
                <a href={"/book2"} target="_blank" rel="noopener noreferrer"><img className="book-img" src="/img/resources/book.png" /></a>
                <p className="center-text">Book 2 focuses on some of the specific rules to reading the Qur'aan.</p>
            </div>
        </div>
    </div>
    )
}

export default Resources;