import React, {useState, useEffect} from 'react';
import TransactionService from '../Services/TransactionService';
import UserService from '../Services/UserService';
import './Transactions.css'

const Transactionsall = () => {

    const [transactions, setTransactions] = useState(null);
    const [balance, setBalance] = useState(null);

    useEffect(()=>{
        TransactionService.getAllTransactions().then(data=> {
            console.log('data',data.users);
            setTransactions(data.users);
        })

        UserService.getUser().then(data=> {
            // console.log('data user ',data.users.balance);
            setBalance(data.users.balance);
        })
    },[])

    const transformDateTimeCreated = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.toUTCString();

        //  + dateTime.getDate() + '-'
        //  + dateTime.getMonth() + '-'
        //  + dateTime.getFullYear() + ', Time: '
        //  + dateTime.getHours() + ":" +
        //  + dateTime.getMinutes() + ":" +
        //  + dateTime.getSeconds();
    }
    if(!transactions) {
        return "no data1";
    }
    return(
        <div className="transactions-container">
                <h1> Transactions </h1>

            <h3>Current Balance: £{balance}</h3>

            <table className="center">
                <thead>
                    <tr>
                    <th>Date</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Description</th>
                    </tr>
                </thead>

                <tbody>


            {
                transactions.map((item, index)=>{
                return (
                    <tr key={index}>
                        <td>{transformDateTimeCreated(item.date)}</td>
                        <td>£{item.debit}</td>
                        <td>£{item.credit}</td>
                        <td>{item.descriptionTrans}</td>
                    </tr>
                )
                })
            }
    </tbody>
    </table>

        </div>
    )
}

export default Transactionsall;