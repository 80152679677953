import React from 'react';

const About = () => {
    return(
        <div className="home-container">
            <h1>About</h1>
            <p>Quran Classroom aims to use technology to engage students in their journey to learning the Quran. </p>
            <p>Phone/Whatsapp/Telegram: +44 7545 122 053</p>

        </div>
    )
}

export default About;