export default {
    getTeacher : ()=>{
        return fetch('/user/teacher')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },
    getTeacherNames : ()=>{
        return fetch('/user/teachernames')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },
    addStudent : getCreateSubsudent => {
        return fetch('/user/teachers',{
            method : "post",
            body : JSON.stringify(getCreateSubsudent),
            headers:{
                'Content-Type' : 'application/json'
            }
        }).then(response=>{
            if(response.status !== 401){
                return response.json().then(data => data);
            }
            else
                return {message : {msgBody : "UnAuthorized"}, msgError : true};
        });
    },

    getMyStudents : ()=>{
        return fetch('/user/getMyStudents')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },



}