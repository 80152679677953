import React, {useState,useRef,useEffect} from 'react';
import AuthService from '../Services/AuthService';
import Message from '../Components/Message';
import './Register.css'
const Register = props=>{
    const [user,setUser] = useState({username: "", email: "", password : ""});
    const [message,setMessage] = useState(null);
    let timerID = useRef(null);

    useEffect(()=>{
        return ()=>{
            clearTimeout(timerID);
        }
    },[]);

    const onChange = e =>{
        setUser({...user,[e.target.name] : e.target.value});
    }

    const resetForm = ()=>{
        setUser({username: "", email:"", password : ""});
    }

    const onSubmit = e =>{
        user.username = user.username.toLowerCase();
        e.preventDefault();
        AuthService.register(user).then(data=>{
            const { message } = data;
            setMessage(message);
            resetForm();
            if(!message.msgError){
                timerID = setTimeout(()=>{
                    props.history.push('/login');
                },2000)
            }
        });
    }



    return(
        <div className="register-container">
            <form onSubmit={onSubmit}>
                <h3 className="middle">Please Register</h3>
                <label htmlFor="fullname" className="sr-only">Full name: </label>
                <input type="text" 
                       name="fullname" 
                       value={user.fullname}
                       onChange={onChange} 
                       className="form-control" 
                       placeholder="Enter Fullname"/>
                <br />
                <br />
                <label htmlFor="username" className="sr-only">Username: </label>
                <input className="lowercase" type="text" 
                       name="username" 
                       value={user.username}
                       onChange={onChange} 
                       className="form-control" 
                       placeholder="Enter Username"/>
                <br />
                <br />
                <label htmlFor="email" className="sr-only">Email add: </label>
                <input type="text" 
                       name="email" 
                       value={user.email}
                       onChange={onChange} 
                       className="form-control" 
                       placeholder="Enter Email"/>
                <br />
                <br />
                <label htmlFor="password" className="sr-only">Password: </label>
                <input type="password" 
                       name="password"
                       value={user.password} 
                       onChange={onChange} 
                       className="form-control" 
                       placeholder="Enter Password"/>
                <br />
                <br />     
                <button className="btn btn-lg btn-primary btn-block" 
                        type="submit">Register</button>
            </form>
            {message ? <Message message={message}/> : null}
        </div>
    )
}

export default Register;