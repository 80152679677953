import React, {useState,useEffect} from 'react';
// import ProfileService from '../Services/ProfileService';
import LessonreportService from '../Services/LessonreportService';
// import Message from './Message';
// import { AuthContext } from '../Context/AuthContext';
import './Lessonreport.css'
// import { 
//     Box,
//     Flex,
//     Avatar,
//     Text,
//     Badge 

// } from "@chakra-ui/core";

const Lessonreport = () =>{
    let getCurrentMonth = new Date().getMonth();
    let getCurrentYear = new Date().getFullYear();
    const [monthSelected, setMonthSelected] = useState(getCurrentMonth);
    const [YearSelected, setYearSelected] = useState(getCurrentYear);
    let total = 0;


    const [reports,setReports] = useState(null);
    
    useEffect(()=>{
        LessonreportService.getLessonreport().then(data =>{
            setReports(data.lessonreport);
            console.log('data.lessonreport', data.lessonreport);
        });
    },[]);

    const getDateMonth = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.getMonth();
    }

    const getDateYear = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.getFullYear();
    }

    const transformDateTimeCreated = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.toUTCString();
        //  + ' ('+dateTime.toLocaleTimeString()+')'

        //  + dateTime.getDate() + '-'
        //  + dateTime.getMonth() + '-'
        //  + dateTime.getFullYear() + ', Time: '
        //  + dateTime.getHours() + ":" +
        //  + dateTime.getMinutes() + ":" +
        //  + dateTime.getSeconds();
    }

    const onChangeGetPos = e => {
        const selectedIndex = e.target.options.selectedIndex;
        console.log('month', e.target.options[selectedIndex].getAttribute('data-month'));
        // setMonthSelected(e.target.options.value);
        setYearSelected(e.target.options[selectedIndex].getAttribute('data-year'));
        setMonthSelected(e.target.options[selectedIndex].getAttribute('data-month'));

        console.log("e value", e.target.value);
    }

    const onChangeHandler = e =>{
        setMonthSelected(e.target.value);
    }

    if (!reports) {
        return <div>No data</div>;
    }
    return(
        <div className="lesson-report-container">
    <section>
                <div className="center">
       
                <h2>Lesson Reports:</h2>

                <div className="mobile">
            <select
                name="month"
                id="month"
                type="text"
                defaultValue={monthSelected}

                // value={substudents.user}
                onChange={onChangeHandler}
                required
            >
                <option value="0">Jan</option>                   
                <option value="1">Feb</option>                   
                <option value="2">Mar</option>
                <option value="3">Apr</option>                   
                <option value="4">May</option>                   
                <option value="5">Jun</option>
                <option value="6">Jul</option>                   
                <option value="7">Aug</option>                   
                <option value="8">Sep</option>
                <option value="9">Oct</option>                   
                <option value="10">Nov</option>                   
                <option value="11">Dec</option>    
            </select>
        </div>
                <select
                name="user"
                id="user"
                type="text"
                defaultValue={YearSelected}

                // value={substudents.user}
                onChange={onChangeGetPos}
                required
            >
                <option data-year="2019">2019</option>                   
                <option data-year="2020">2020</option>                   
                <option data-year="2021">2021</option>    
                <option data-year="2022">2022</option>    
                <option data-year="2023">2023</option>    
                <option data-year="2024">2024</option>    
                <option data-year="2025">2025</option>
            </select>

            <br />
            <br />
        
            <div className="desktop">
                <button className="buttonStyle" onClick={onChangeHandler} value="0"> Jan </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="1"> Feb </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="2"> Mar </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="3"> Apr </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="4"> May </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="5"> Jun </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="6"> Jul </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="7"> Aug </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="8"> Sep </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="9"> Oct </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="10"> Nov </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="11"> Dec </button>
            </div>
            </div>
            <br />

                <table className="center">
      <thead>
        <tr>
          <th>Date</th>
          <th>Teacher</th>
          <th>Student</th>
          <th>Lesson Number</th>
          <th>Current Stage</th>
          <th>Description</th>
          <th>Homework</th>
        </tr>
      </thead>

      <tbody>

                {
                    reports.map((student, index)=> {
                        return (
                            getDateMonth((student.date)) == monthSelected && getDateYear(student.date) == YearSelected ? 
                            
                            <tr key={index}>
                        <td>{transformDateTimeCreated(student.date)}</td>
                        <td>{student.teacher}</td>
                        <td>{student.name}</td>
                        <td>{student.lessonnumber}</td>
                        <td>{student.currentstage}</td>
                        <td>{student.description}</td>
                        <td>{student.homework}</td>
                    </tr>
                       : null
                    )
                })
            }
              </tbody>
        </table> 
</section>
        </div>
    );

}

export default Lessonreport;


