export default {
    getUsers : ()=>{
        return fetch('/user/users')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },

    getUser : ()=>{
        return fetch('/user/user')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },
   
    deleteStudentFromTeacher : deleteStudentFromTeacher => {
        return fetch('/user/deletestudentfromteacher',{
            method : "post",
            body : JSON.stringify(deleteStudentFromTeacher),
            headers:{
                'Content-Type' : 'application/json'
            }
        }).then(response=>{
            if(response.status !== 401){
                return response.json().then(data => data);
            }
            else
                return {message : {msgBody : "UnAuthorized"}, msgError : true};
        });
    },
    updaterole : updaterole => {
        return fetch('/user/updaterole',{
            method : "post",
            body : JSON.stringify(updaterole),
            headers:{
                'Content-Type' : 'application/json'
            }
        }).then(response=>{
            if(response.status !== 401){
                return response.json().then(data => data);
            }
            else
                return {message : {msgBody : "UnAuthorized"}, msgError : true};
        });
    },
}

