export const Stages = [
  {
    value: "Stage1a",
    stage: "Stage 1a - Alif-Kha",
  },
  {
    value: "Stage1b",
    stage: "Stage 1b - Alif-Ghayn",
  },
  {
    value: "Stage1c",
    stage: "Stage 1c - Full Alphabet",
  },
  {
    value: "Stage2a",
    stage: "Stage 2a - Letter Positions (Alif-Ghayn)",
  },
  {
    value: "Stage2b",
    stage: "Stage 2b - Letter Positions (Full Alphabet)",
  },  
  {
    value: "Stage3a",
    stage: "Stage 3a - Fat'ha",
  },
  {
    value: "Stage3b",
    stage: "Stage 3b - Kasrah",
  },
  {
    value: "Stage3c",
    stage: "Stage 3c - Dammah",
  },
  {
    value: "Stage4a",
    stage: "Stage 4a - Alif Madd",
  },
  {
    value: "Stage4b",
    stage: "Stage 4b - Yaa Madd",
  },
  {
    value: "Stage4c",
    stage: "Stage 4c - Waw Madd",
  },
  {
    value: "Stage5a",
    stage: "Stage 5a - Fat'hatayn",
  },
  {
    value: "Stage5b",
    stage: "Stage 5b - Kasratayn ",
  },
  {
    value: "Stage5c",
    stage: "Stage 5c - Dammatayn",
  },
  {
    value: "Stage6a",
    stage: "Stage 6a - Sukoon",
  },
  {
    value: "Stage6b",
    stage: "Stage 6b - Shaddah",
  },
  {
    value: "Stage6c",
    stage: "Stage 6c - Ash-Shamsiyyah wal Qamariyyah",
  },
  {
    value: "Stage6d",
    stage: "Stage 6d - Hamzatul-Wasl",
  },
  {
    value: "Stage7a",
    stage: "Stage 7a - Extended Madds",
  },
  {
    value: "Stage7b",
    stage: "Stage 7b - Stops",
  },
  {
    value: "Stage8",
    stage: "Stage 8 - Noon Saakin & Tanween",
  },
  {
    value: "Stage9",
    stage: "Stage 9 - Meem Saakin",
  },
  {
    value: "Stage10",
    stage: "Stage 10 - Additional Rules",
  },
]

// <option>Stage1a</option>
// <option>Stage1b</option>
// <option>Stage1c</option>
// <option>Stage2a</option>
// <option>Stage2b</option>
// <option>Stage3a</option>
// <option>Stage3b</option>
// <option>Stage3c</option>
// <option>Stage4a</option>
// <option>Stage4b</option>
// <option>Stage4c</option>
// <option>Stage5a</option>
// <option>Stage5b</option>
// <option>Stage5c</option>
// <option>Stage6a</option>
// <option>Stage6b</option>
// <option>Stage6c</option>
// <option>Stage6d</option>
// <option>Stage7a</option>
// <option>Stage7b</option>
// <option>Stage8</option>
// <option>Stage9</option>
export const QuraanVerses = [
    {
      value: 1,
      surah: "Al-Faatiha",
      verses: 7
    },
    {
        value: 2,
        surah: "Al-Baqarah",
        verses: 286
    },
    {
      value: 3,
      surah: "Aali-Imraan",
      verses: 200
    },
    {
      value: 4,
      surah: "An-Nisaa",
      verses: 176
    },
    {
      value: 5,
      surah: "Al-Maa'idah",
      verses: 120
    },
    {
      value: 6,
      surah: "Al An'aam",
      verses: 165
    },
    {
      value: 7,
      surah: "Al-A'raf",
      verses: 206
    },
    {
      value: 8,
      surah: "Al-Anfaal",
      verses: 75
    },
    {
      value: 9,
      surah: "At-Tawbah",
      verses: 129
    },
    {
      value: 10,
      surah: "Yunus",
      verses: 109
    },
    {
      value: 11,
      surah: "Huud",
      verses: 123
    },
    {
      value: 12,
      surah: "Yusuf",
      verses: 111
    },
    {
      value: 13,
      surah: "Ar-Ra'd",
      verses: 43
    },
    {
      value: 14,
      surah: "Ibrahim",
      verses: 52
    },
    {
      value: 15,
      surah: "Al-Hijr",
      verses: 99
    },
     {
      value: 16,
      surah: "An-Nahl",
      verses: 128
    },
    {
      value: 17,
      surah: "Al-Israa",
      verses: 111
    },
    {
      value: 18,
      surah: "Al-Kahf",
      verses: 110
    },
    {
      value: 19,
      surah: "Maryam",
      verses: 98
    },
    {
      value: 20,
      surah: "Ta-ha",
      verses: 135
    },
    {
      value: 21,
      surah: "Al-Anbiyaa",
      verses: 112
    },
    {
      value: 22,
      surah: "Al-Hajj",
      verses: 78
    },
    {
      value: 23,
      surah: "Al-Mu'minoon",
      verses: 118
    },
    {
      value: 24,
      surah: "An-Noor",
      verses: 64
    },
    {
      value: 25,
      surah: "Al-Furqaan",
      verses: 77
    },
     {
      value: 26,
      surah: "Ash-Shu'ara",
      verses: 227
    },
    {
      value: 27,
      surah: "An-Naml",
      verses: 93
    },
    {
      value: 28,
      surah: "Al-Qasas",
      verses: 88
    },
    {
      value: 29,
      surah: "Al-Ankaboot",
      verses: 69
    },
    {
      value: 30,
      surah: "Ar-Room",
      verses: 60
    },
    {
      value: 31,
      surah: "Luqmaan",
      verses: 34
    },
    {
      value: 32,
      surah: "As-Sajda",
      verses: 30
    },
    {
      value: 33,
      surah: "Al Ahzaab",
      verses: 118
    },
    {
      value: 34,
      surah: "Saba",
      verses: 54
    },
    {
      value: 35,
      surah: "Faatir",
      verses: 45
    },
     {
      value: 36,
      surah: "Yaseen",
      verses: 83
    },
    {
      value: 37,
      surah: "As-Saaffaat",
      verses: 182
    },
    {
      value: 38,
      surah: "Saad",
      verses: 88
    },
    {
      value: 39,
      surah: "Az-Zumar",
      verses: 75
    },
    {
      value: 40,
      surah: "Ghafir",
      verses: 85
    },
    {
      value: 41,
      surah: "Fussilat",
      verses: 54
    },
    {
      value: 42,
      surah: "Ash-Shooraa",
      verses: 53
    },
    {
      value: 43,
      surah: "Az-Zukhruf",
      verses: 89
    },
    {
      value: 44,
      surah: "Ad-Dukhaan",
      verses: 59
    },
    {
      value: 45,
      surah: "Al-Jaathiyah",
      verses: 37
    },
     {
      value: 46,
      surah: "Al-Ahqaaf",
      verses: 35
    },
    {
      value: 47,
      surah: "Muhammad",
      verses: 38
    },
    {
      value: 48,
      surah: "Al-Fath",
      verses: 29
    },
    {
      value: 49,
      surah: "Al-Hujuraat",
      verses: 18
    },
    {
      value: 50,
      surah: "Qaaf",
      verses: 45
    },
    {
      value: 51,
      surah: "Adh-Dhaariyaat",
      verses: 60
    },
    {
      value: 52,
      surah: "At-Toor",
      verses: 49
    },
    {
      value: 53,
      surah: "An-Najm",
      verses: 62
    },
    {
      value: 54,
      surah: "Al-Qamar",
      verses: 55
    },
    {
      value: 55,
      surah: "Ar-Rahmaan",
      verses: 78
    },
     {
      value: 56,
      surah: "Al-Waaqi'ah",
      verses: 96
    },
    {
      value: 57,
      surah: "Al-Hadeed",
      verses: 29
    },
    {
      value: 58,
      surah: "Al-Mujaadilah",
      verses: 22
    },
    {
      value: 59,
      surah: "Al-Hashr",
      verses: 24
    },
    {
      value: 60,
      surah: "Al-Mumtahanah",
      verses: 13
    },
    {
      value: 61,
      surah: "As-Saff",
      verses: 14
    },
    {
      value: 62,
      surah: "Al-Jumu'ah",
      verses: 11
    },
    {
      value: 63,
      surah: "Al-Munaafiqoon",
      verses: 11
    },
    {
      value: 64,
      surah: "At-Taghaabun",
      verses: 18
    },
    {
      value: 65,
      surah: "At-Talaaq",
      verses: 12
    },
     {
      value: 66,
      surah: "At-Tahreem",
      verses: 12
    },
    {
      value: 67,
      surah: "Al-Mulk",
      verses: 30
    },
    {
      value: 68,
      surah: "Al-Qalam",
      verses: 52
    },
    {
      value: 69,
      surah: "Al-Haaqqah",
      verses: 52
    },
    {
      value: 70,
      surah: "Al-Ma'aarij",
      verses: 44
    },
    {
      value: 71,
      surah: "Nooh",
      verses: 28
    },
    {
      value: 72,
      surah: "Al-Jinn",
      verses: 28
    },
    {
      value: 73,
      surah: "Al-Muzzammil",
      verses: 20
    },
    {
      value: 74,
      surah: "Al-Muddaththir",
      verses: 56
    },
    {
      value: 75,
      surah: "Al-Qiyaamah",
      verses: 40
    },
     {
      value: 76,
      surah: "Al-Insaan",
      verses: 31
    },
    {
      value: 77,
      surah: "Al-Mursalaat",
      verses: 50
    },
    {
      value: 78,
      surah: "An-Naba'",
      verses: 40
    },
    {
      value: 79,
      surah: "An-Naazi'aat",
      verses: 46
    },
    {
      value: 80,
      surah: "Abasa",
      verses: 42
    },
    {
      value: 81,
      surah: "At-Takweer",
      verses: 29
    },
    {
      value: 82,
      surah: "Al-Infitaar",
      verses: 19
    },
    {
      value: 83,
      surah: "Al-Mutaffifeen",
      verses: 36
    },
    {
      value: 84,
      surah: "Al-Inshiqaaq",
      verses: 25
    },
    {
      value: 85,
      surah: "Al-Burooj",
      verses: 22
    },
     {
      value: 86,
      surah: "At-Taariq",
      verses: 17
    },
    {
      value: 87,
      surah: "Al-A'alaa",
      verses: 19
    },
    {
      value: 88,
      surah: "Al-Ghaashiyah",
      verses: 26
    },
    {
      value: 89,
      surah: "Al-Fajr",
      verses: 30
    },
    {
      value: 90,
      surah: "Al-Balad",
      verses: 20
    },
    {
      value: 91,
      surah: "Ash-Shams",
      verses: 15
    },
    {
      value: 92,
      surah: "Al-Layl",
      verses: 21
    },
    {
      value: 93,
      surah: "Ad-Dhuha",
      verses: 11
    },
    {
      value: 94,
      surah: "Ash-Sharh",
      verses: 8
    },
    {
      value: 95,
      surah: "At-Teen",
      verses: 8
    },
     {
      value: 96,
      surah: "Al-'Alaq",
      verses: 19
    },
    {
      value: 97,
      surah: "Al-Qadr",
      verses: 5
    },
    {
      value: 98,
      surah: "Al-Bayyinah",
      verses: 8
    },
    {
      value: 99,
      surah: "Az-Zalzalah",
      verses: 8
    },
    {
      value: 100,
      surah: "Al-'Aadiyaat",
      verses: 11
    },
    {
      value: 101,
      surah: "Al-Qaari'ah",
      verses: 11
    },
    {
      value: 102,
      surah: "At-Takaathur",
      verses: 8
    },
    {
      value: 103,
      surah: "Al-'Asr",
      verses: 3
    },
    {
      value: 104,
      surah: "Al-Humazah",
      verses: 9
    },
    {
      value: 105,
      surah: "Al-Feel",
      verses: 5
    },
     {
      value: 106,
      surah: "Quraysh",
      verses: 4
    },
    {
      value: 107,
      surah: "Al-Maa'oon",
      verses: 7
    },
    {
      value: 108,
      surah: "Al-Kawthar",
      verses: 3
    },
    {
      value: 109,
      surah: "Al-Kaafiroon",
      verses: 6
    },
    {
      value: 110,
      surah: "An-Nasr",
      verses: 3
    },
    {
      value: 111,
      surah: "Al-Masad",
      verses: 5
    },
    {
      value: 112,
      surah: "Al-Ikhlaas",
      verses: 4
    },
    {
      value: 113,
      surah: "Al-Falaq",
      verses: 5
    },
    {
      value: 114,
      surah: "An-Naas",
      verses: 6
    },
]