import React, {useState,useEffect} from 'react';
import UserService from '../Services/UserService';
import './Profile.css'

import Teachermylessons from './Teachermylessons';
import Parentmylessons from './Parentmylessons';

const Profile = () =>{
    
    const [role,setRole] = useState(null);

    useEffect(()=>{
        UserService.getUser().then(data =>{
            setRole(data.users.role);
        });
    },[]);

    if (!role) {
        return <div>No data</div>;
    }
    return(
        <div className="profile-container">
                {
                    role === "teacher" ? 
                    <Teachermylessons /> : null
                }
                {
                    role === "user" ? 
                    <Parentmylessons /> : null
                }
        </div> 
    );

}

export default Profile;