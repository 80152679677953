import React, {useState,useEffect} from 'react';
import UserService from '../Services/UserService';
import './Teachermylessons.css';
import './Live.css';

import { 
    Box,
    Flex,
    Avatar,
    Text,
} from "@chakra-ui/react";

const Teachermylessons = () =>{
    const [students,setStudents] = useState(null);
    const [balance,setBalance] = useState(null);
    const [techingDay, setTeachingDay] = useState(null);
    let currentDay = new Date;
    currentDay = currentDay.getDay();

    useEffect(()=>{
        UserService.getUser().then(data =>{
            setStudents(data.users.myStudent);
            setBalance(data.users.balance)
            // console.log('data', data.users.myStudent);
        });
    },[]);

    // const getDays = (string) => {
    //     string = string.toLowerCase();
    //     if(string.match(/sun/g) || string.match(/sunday/)) {
    //         setTeachingDay(0);
    //     }
    //     if(string.match(/mon/g) || string.match(/monday/)) {
    //         setTeachingDay(1);
    //     }
    //     if(string.match(/tue/g) || string.match(/tuesday/)) {
    //         setTeachingDay(2);
    //     }
    //     if(string.match(/wed/g) || string.match(/wednesday/)) {
    //         setTeachingDay(3);
    //     }
    //     if(string.match(/thu/g) || string.match(/thursday/)) {
    //         setTeachingDay(4);
    //     }
    //     if(string.match(/fri/g) || string.match(/friday/)) {
    //         setTeachingDay(5);
    //     }
    //     if(string.match(/sat/g) || string.match(/saturday/)) {
    //         setTeachingDay(6);
    //     }
    // }
    
    const getSurahName = (surahNumber) => {

        switch(surahNumber) {
            case 1:
                return "Al-Faatiha";
            case 2:
                return "Al-Baqarah";
            case 3:
                return "Aali-Imraan";
            case 4:
                return "An-Nisaa";
            case 5:
                return "Al-Maa'idah";
            case 6:
                return "Al An'aam";
            case 7:
                return "Al-A'raf";
            case 8:
                return "Al-Anfaal";
            case 9:
                return "At-Tawbah";
            case 10:
                return "Yunus";
            case 11:
                return "Huud";
            case 12:
                return "Yusuf";
            case 13:
                return "Ar-Ra'd";
            case 14:
                return "Ibrahim";
            case 15:
                return "Al-Hijr";
            case 16:
                return "An-Nahl";
            case 17:
                return "Al-Israa";
            case 18:
                return "Al-Kahf";
            case 19:
                return "Maryam";
            case 20:
                return "Ta-ha";
            case 21:
                return "Al-Anbiyaa";
            case 22:
                return "Al-Hajj";
            case 23:
                return "Al-Mu'minoon";
            case 24:
                return "An-Noor";
            case 25:
                return "Al-Furqaan";
            case 26:
                return "Ash-Shu'ara";
            case 27:
                return "An-Naml";
            case 28:
                return "Al-Qasas";
            case 29:
                return "Al-Ankaboot";
            case 30:
                return "Ar-Room";
            case 31:
                return "Luqmaan";
            case 32:
                return "As-Sajda";
            case 33:
                return "Al Ahzaab";
            case 34:
                return "Saba";
            case 35:
                return "Faatir";
            case 36:
                return "Yaseen";
            case 37:
                return "As-Saaffaat";
            case 38:
                return "Saad";
            case 39:
                return "Az-Zumar";
            case 40:
                return "Ghafir";
            case 41:
                return "Fussilat";
            case 42:
                return "Ash-Shooraa";
            case 43:
                return "Az-Zukhruf";
            case 44:
                return "Ad-Dukhaan";
            case 45:
                return "Al-Jaathiyah";
            case 46:
                return "Al-Ahqaaf";
            case 47:
                return "Muhammad";
            case 48:
                return "Al-Fath"; 
            case 49:
                return "Al-Hujuraat";
            case 50:
                return "Qaaf";
            case 51:
                return "Adh-Dhaariyaat";
            case 52:
                return "At-Toor";
            case 53:
                return "An-Najm";
            case 54:
                return "Al-Qamar";
            case 55:
                return "Ar-Rahmaan";
            case 56:
                return "Al-Waaqi'ah";
            case 57:
                return "Al-Hadeed";
            case 58:
                return "Al-Mujaadilah";
            case 59:
                return "Al-Hashr";
            case 60:
                return "Al-Mumtahanah";
            case 61:
                return "As-Saff";
            case 62:
                return "Al-Jumu'ah";
            case 63:
                return "Al-Munaafiqoon";
            case 64:
                return "At-Taghaabun";
            case 65:
                return "At-Talaaq";
            case 66:
                return "At-Tahreem";
            case 67:
                return "Al-Mulk";
            case 68:
                return "Al-Qalam";
            case 69:
                return "Al-Haaqqah";
            case 70:
                return "Al-Ma'aarij";
            case 71:
                return "Nooh";
            case 72:
                return "Al-Jinn";
            case 73:
                return "Al-Muzzammil";
            case 74:
                return "Al-Muddaththir";
            case 75:
                return "Al-Qiyaamah";
            case 76:
                return "Al-Insaan";
            case 77:
                return "Al-Mursalaat";
            case 78:
                return "An-Naba'";
            case 79:
                return "An-Naazi'aat";
            case 80:
                return "Abasa";
            case 81:
                return "At-Takweer";
            case 82:
                return "Al-Infitaar";
            case 83:
                return "Al-Mutaffifeen";
            case 84:
                return "Al-Inshiqaaq";
            case 85:
                return "Al-Burooj";
            case 86:
                return "At-Taariq";
            case 87:
                return "Al-A'alaa";
            case 88:
                return "Al-Ghaashiyah";
            case 89:
                return "Al-Fajr";
            case 90:
                return "Al-Balad";
            case 91:
                return "Ash-Shams";
            case 92:
                return "Al-Layl";
            case 93:
                return "Ad-Dhuha";
            case 94:
                return "Ash-Sharh";
            case 95:
                return "At-Teen";
            case 96:
                return "Al-'Alaq";
            case 97:
                return "Al-Qadr";
            case 98:
                return "Al-Bayyinah";
            case 99:
                return "Az-Zalzalah";
            case 100:
                return "Al-'Aadiyaat";
            case 101:
                return "Al-Qaari'ah";
            case 102:
                return "At-Takaathur";
            case 103:
                return "Al-'Asr";
            case 104:
                return "Al-Humazah";
            case 105:
                return "Al-Feel";
            case 106:
                return "Quraysh";
            case 107:
                return "Al-Maa'oon";
            case 108:
                return "Al-Kawthar";
            case 109:
                return "Al-Kaafiroon";
            case 110:
                return "An-Nasr"; 
            case 111:
                return "Al-Masad";
            case 112:
                return "Al-Ikhlaas";
            case 113:
                return "Al-Falaq";
            case 114:
                return "An-Naas";                               
            default: 
                return "not set";
                
        }
    }

    if (!students) {
        return <div>No data</div>;
    }
    return(
        <div className="profile-container">
            
            <h1>Teacher Account - Account Balance: £{balance} </h1>
            <div className="student-session">
            {
                    students.map(student =>{
                        return <div key={student._id} className="student-box">

                    <Flex direction="column" align="center">
                    <Avatar mt="5" size="xl" src="/img/home/quraan.png" />
                    <Box>
                        <Text fontWeight="bold">
                            {student.name} 
                        </Text>
                        
                    </Box>     
                    <Box>
                        <Text fontWeight="bold">
                            [{student.dob}]
                        </Text>
                    </Box> 
                    <Box>
                        <Text fontWeight="bold">
                            Time: {student.lessontime}
                        </Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">
                            <a href={`/live/${student.lessoncode}`} target="_blank" rel="noopener noreferrer">Internal Link [click]</a>
                        </Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">
                            <a href={"https://whiteboard.explaineverything.com/#"+student.lessoncode} target="_blank" rel="noopener noreferrer">External link [click]</a>
                        </Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">
                            iPad Code: {student.lessoncode}
                        </Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">                        
                    <a href={`/rwarabicfromprofile/${student.currentstage}`} target="_blank" rel="noopener noreferrer">{student.currentstage}</a>
                        </Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">                        
                        <a href={`/quraan/${student.surah}/${student.ayahfrom}/${student.ayahto}`} target="_blank" rel="noopener noreferrer">Qur'aan: {`Surah ${getSurahName(student.surah)}:${student.ayahfrom}-${student.ayahto}`}</a>
                        </Text>
                    </Box>
                    </Flex>            

                        </div> 
                    })
                }
            </div>
        </div>
    );

}

export default Teachermylessons;