import React, {useState, useEffect} from 'react';
import UserService from '../Services/UserService';
import './Admin.css'

const Admin = ()=>{
    const [getUsers, setGetUsers] = useState(null);

    useEffect(()=>{
        UserService.getUsers().then(data =>{
            console.log(data.users);
            setGetUsers(data.users);
        })

    },[])

    if(!getUsers) {
        return "no data";
    }
    return (
        <div className="middle">
        
            <h1>Admin Page</h1>

            <h2>Admins</h2>
               
            <table>
                    <tr>
                        <th>Username</th>
                        <th>Role</th>
                        <th>Cost Per Lesson</th>
                        <th>Account balance</th>
                    </tr>
                {
                    getUsers.map((user, index)=>{
                        return ( <>

                            {user.role == "admin" ?
                        
                            <tr key={index}>
                                <td>{user.username}</td>
                                <td>{user.role}</td>
                                <td>{user.costperlesson}</td>                         
                                <td>{user.balance}</td>
                            </tr>
                            :
                            null}
                            
                            </>)
                    })
                }    
                
                </table>
                <h2>Teachers</h2>

                <table>
                    <tr>
                        <th>Username</th>
                        <th>email</th>
                        <th>Role</th>
                        <th>Cost Per Lesson</th>
                        <th>Teacher's students</th>
                        <th>Account balance</th>
                    </tr>
                {
                    getUsers.map((user, index)=>{
                        return ( <>

                            {user.role == "teacher" ?
                        
                            <tr key={index}>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.costperlesson}</td>
                             
                                <td>
                                    {
                                        user.myStudent.map((student, index)=> {
                                            return student.name + " - "
                                        })
                                    }
                                </td>
                                <td>{user.balance}</td>
                            </tr>
                            :
                            null}
                            
                            </>)
                    })
                }    
                
                </table>
                <h2>Users</h2>

                <table>
                    <tr>
                        <th>Username</th>
                        <th>email</th>
                        <th>Role</th>
                        <th>Cost Per Lesson</th>
                        <th>Substudents</th>
                        <th>Account balance</th>
                    </tr>
                {
                    getUsers.map((user, index)=>{
                        return ( <>

                            {user.role == "user" ?
                        
                            <tr key={index}>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.costperlesson}</td>
                                <td>
                                    {
                                        user.substudent.map((student, index)=> {
                                            return student.name + " - "
                                        })
                                    }
                                </td>
                                <td>{user.balance}</td>
                            </tr>
                            :
                            null}
                            
                            </>)
                    })
                }    
                
                </table>



            <hr />
            <h2>User Account</h2>
            <p>Edit a user account: <a href="/parentedit">Edit</a></p>

            <hr />
            
            <h2>Sub-Student</h2>
            <p>Add a sub-student account: <a href="/substudentadd">Edit</a></p> 
            <p>Edit a sub-student account: <a href="/substudent">Edit</a></p> 
            
            <hr />

            <h2>Teachers</h2>
            <p>Add child to a teacher's account <a href="/teachers">Edit</a></p> 

            <hr />

            <h2>Lesson Reports</h2>
            <p>edit a lesson report <a href="/teachers">Edit</a></p> 

            <h2>View Transactions / edit?</h2>


        </div>
    )
}
export default Admin;