import React from 'react';
import './Policy.css';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"

export const Policy = () => {
    return (
        <div className="Policy-Container">
            <h1>Qur'aan Classroom Policies</h1>
            <p>
                As we continue to grow and increase both our student-base and the number of staff on our team, we have realised the importance of implementing certain policies in order to aid our students’ overall progress and the smooth running of their lessons on a weekly basis.
            </p>


            <Tabs align="center" size="md" variant="enclosed">
                <TabList>
                    <Tab>Student</Tab>
                    <Tab>Parent</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <h3>As a student of Arabic Classroom, I agree that I will:</h3>
                        <p>1. be on time for my lesson</p>
                        <p>2. check that my laptop or tablet has enough battery for my lesson</p>
                        <p>3. explain if there is a problem that my teacher cannot see</p>
                        <p>4. be polite and use my manners</p>
                        <p>5. follow my teacher’s instructions</p>
                        <p>6. listen to my teacher and answer his or her questions</p>
                        <p>7. focus on my lesson and not get distracted with other activities</p>
                        <p>8. ask if there is anything I need help with or don’t understand</p>
                        <p>9. have my snacks before or after my lesson</p>
                        <p>10. do the homework my teacher sets for me because this will help me improve</p>
                        <br />
                        <p>I understand that by following these rules, I will inshaaAllah get the most out of my lessons and progress in my Qur’aan/Arabic studies.</p>
                        <p>Signed: ……….……….……….……….……….……….……….……….……….……….………. Date: ……….……….……….</p>
                    </TabPanel>
                    <TabPanel>
                        <h3>1. Timekeeping and punctuality</h3>
                        <p>
                            As the demand on our time increases, so does the importance of punctuality for our lessons. All of our teachers have various restrictions on their time and so it isn’t always possible or fair for them to wait or delay the start time of a class. We therefore ask that students are ready and logged on for their lesson at the correct time. Teachers will not wait longer than 10 minutes for students to arrive and it is their right to end the session at the agreed time, regardless of how late the lesson started.
                        </p>

                        <h3>2. Behaviour</h3>
                        <p>
                            While we have been blessed with very polite and bright students, may Allah bless them all, it is always important to have a behavioural policy in place when working with children. In order for both parents and students to understand our expectations, we have drafted a contract between ourselves and our students. We ask that students read the attached document, print it, sign it and keep it as a reminder of what we expect from them during our time together.
                        </p>

                        <h3>3. Charges for missed lessons</h3>
                        <p>
                            Unfortunately, we have experienced a number of last-minute lesson cancellations or reschedules. While we appreciate that life is unpredictable and that there are a number of factors that could affect your child’s weekly lesson, we also have a duty of responsibility to our staff. Therefore, you will be charged for scheduled lessons unless we receive notice of cancellation or a request to reschedule 24 hours in advance of the lesson. In cases of emergency or exceptional circumstances, you should contact Arabic Classroom directly via phone and your request will be dealt with on a case by case basis.
                        </p>

                        <h3>4. Progress Reports</h3>
                        <p>
                            As standard practice at Arabic Classroom, our teachers complete a report after every session with a student. These reports usually contain a summary of the lesson, any praiseworthy remarks, areas of struggle or behavioural notes, and what homework students should complete before the next lesson. We strongly encourage parents to read these reports from time to time (easily accessible under ‘My Progress’ on arabic-classroom.com) as we believe it can contribute to their children’s success and keep them motivated. By monitoring your children’s progress, praising their successes and asking about their difficulties, you are showing them that you’re interested in what they’re learning and that, as with any other academic subject, it is important. 
                        </p>

                        <h3>5. Homework</h3>
                        <p>
                            As with any subject, the more time a student spends practising or revising, the more likely they are to improve and progress. Arabic Classroom’s curriculum has been designed with self-study as a cornerstone of the process. Our expectation is that our students complete a minimum of 15 minutes of self-study twice a week in addition to their one-to-one session. Alhamdulilah, the course has been designed in such a way that they can practise from our book (PDF freely available under ‘Coursebook’ on arabic-classroom.com) or from the vast bank of online resources (see ‘My Courses/Read and Write Arabic’ on arabic-classroom.com). With the majority of our students studying only 30 minutes a week with a teacher, homework and revision is vital if they are to see any progression in their ability.
                        </p>

                        <h3>6. Communication</h3>
                        <p>
                            At Arabic Classroom we encourage and value communication between our parents, students and teachers as it helps build a relationship of trust and mutual respect. We have designated channels through which our staff members can be contacted including email, Whatsapp and Telegram. However, we expect that Arabic Classroom is always included in any messages between parents and teachers. This is vital for a number of reasons, most especially for transparency in the case of any miscommunications but also to avoid any unprofessional conduct on either side. We also ask that students and parents refrain from giving gifts to their teachers, as this may impact the student-teacher dynamic.
                        </p>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    )
}

export default Policy;