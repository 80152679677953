import React, {useState, useContext, useEffect} from 'react';
import Lessonreportcreate from './Lessonreportcreate';
import UserService from '../Services/UserService';
import { AuthContext } from '../Context/AuthContext';

import {
    Box, 
    Stack,
    Button,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
  } from "@chakra-ui/react";

const Live = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const firstField = React.useRef();
    const btnRef = React.useRef();

    const {user} = useContext(AuthContext);

    const [students,setStudents] = useState(null);

    let url = window.location.pathname;
    let lessoncode = url.substring(url.lastIndexOf("/") + 1, url.length);   


    useEffect(()=>{
        UserService.getUser().then(data =>{
            if(data.users.role === "user") {
              setStudents(data.users.substudent);
            } else {
              setStudents(data.users.myStudent);
            }
        });
    },[]);


    if (!students) {
        return <div>No data</div>;
    }
    return (
        <div>
          {
                    user.role === "teacher" ? 
                    <Button width="55" height="12" position="absolute" top="1.9" left="1.9" zIndex="10" ref={btnRef} leftIcon="add" variantColor="teal" onClick={onOpen}>
                        Add Report
                    </Button>
                    : null
                }
          <iframe title="live-session" className="vh" allow="microphone *; camera *; speakers *; fullscreen *;" src={'https://whiteboard.explaineverything.com/#'+lessoncode} width="100%" height="800px" frameborder="0"></iframe>

    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        finalFocusRef={btnRef}
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Add Lesson Report
            </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <Box>
                {
                    user.role === "teacher" ? 
                    <Lessonreportcreate /> : null
                }
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      
    </>
        </div>
    )
}
export default Live;