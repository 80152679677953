import { get } from 'mongoose';
import React, {useEffect, useState} from 'react';
import UserService from '../Services/UserService';
import './Substudent.css';
import './Parentedit.css';
import Credit from './Credit'

import {
    Heading,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button, 
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,PopoverCloseButton,
    PopoverHeader,
    PopoverBody
  } from "@chakra-ui/react"

const Parentedit = () => {
    const [size, setSize] = React.useState("md")
    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const handleClick = (newSize) => {
      setSize(newSize)
      onOpen()
    }
    const sizes = ["md"]


    let getCurrentMonth = new Date().getMonth();
    let getCurrentYear = new Date().getFullYear();
    const [users, setUsers] = useState(null);
    const [user, setUser] = useState(null);
    const [monthSelected, setMonthSelected] = useState(getCurrentMonth);
    const [YearSelected, setYearSelected] = useState(getCurrentYear);
    const [display, setDisplay] = useState({
        lessonreport: true,
        transactions: false
    })
    const [userID, setUserID] = useState(null);

    const [teacherID, setTeacherID] = useState(null);
    
    const [myStudents, setMyStudents] = useState([]);
    let total = 0;
    let num = -1;
    let [arrayPos,setArrayPos] = useState(0);
    
    const onChange = e => {
        setArrayPos(e.target.value);

        console.log('arraypos', arrayPos);
    }

    const showReports = () => {
        setDisplay({
            lessonreport: true,
            transactions: false
        })
    }

    const showTransactions = () => {
        setDisplay({
            lessonreport: false,
            transactions: true
        })
    }
    

    const onSubmit = e =>{
        e.preventDefault();
    }

    useEffect(()=>{
        UserService.getUsers().then(data=> {
            setUsers(data.users);
            setUser(data.users[arrayPos])
            setTeacherID(data.users[arrayPos]._id);
            setUserID(data.users[arrayPos]._id);
            setMyStudents(data.users[arrayPos].myStudent);
              
        });

        

        
    },[arrayPos]);



    const getDateMonth = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.getMonth();
    }

    const getDateYear = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.getFullYear();
    }

    const transformDateTimeCreated = (dateTimeCreated) => {
        let dateTime = new Date(dateTimeCreated);
        return dateTime.toUTCString();
    }

    const onChangeGetPos = e => {
        const selectedIndex = e.target.options.selectedIndex;
        console.log('month', e.target.options[selectedIndex].getAttribute('data-month'));
        setMonthSelected(e.target.options[selectedIndex].getAttribute('data-month'));
        setYearSelected(e.target.options[selectedIndex].getAttribute('data-year'));
    }

    const onChangeHandler = e =>{
        setMonthSelected(e.target.value);
    }

    const deleteUser = (student, index) => {
        console.log('index', index);
        console.log('mystudents array', myStudents);
        const dataID = {
            teacherID: teacherID,
            studentID: student._id
        }
        console.log("userId", student._id);
        UserService.deleteStudentFromTeacher(dataID).then(data=>{});
        
        
        if(index !== -1) {
            let newArray = [...myStudents];
            newArray.splice(index, 1);
            console.log('array',newArray);

            setMyStudents(newArray);
        }
        console.log('my students', myStudents);
    }

    const roleHandler = (e) => {
        const data = {
            userID: userID,
            roleValue: e.target.value
        }
        setUser({...user, role: e.target.value})
       
        UserService.updaterole(data).then(data=>{});
    }
   
    if(!user) {
        return "no data";
    }
    if(!users) {
        return "no data";
    }
    if(!setMonthSelected) {
        return "no data";
    }
    if(!myStudents) {
        return "no data";
    }
    return(
        <div className="parentedit-container center">
            <div className="edit-form">
                <Heading>Edit Form</Heading>

                <form onSubmit={onSubmit}>

            
            
 
        <Heading htmlFor="user">User:</Heading> <br />
            
            <select
                name="user"
                id="user"
                type="text"
                value={users.user}
                onChange={onChange}
                required
            >  
                <option hidden>Choose here</option>
                {
                    users.map((user, index)=>{
                        num++;
                        return (
                        <option key={index} value={num}>{num}: {(user.username)}</option>
                        )
                    })
                }
            </select>         
                
            <br />          
            <br /> 
            </form>

         
            <div className="user-info">
                <Heading size="lg">Username: {user.username}</Heading>
                <Heading size="lg">Balance: £{user.balance}</Heading>


                <Heading htmlFor="roll" size="lg">My role:</Heading>

            
                    <select
                        name="user"
                        id="user"
                        type="text"
                        onChange={roleHandler}
                        required
                        value={user.role}
                    >  
                        <option hidden>Select Role</option>
                        {/* <option key={num} value="admin">Admin</option> */}
                        <option value="teacher">Teacher</option>
                        <option value="user">User</option>

                    </select>         


                <Heading size="lg">Cost per lesson: £{user.costperlesson}</Heading>
            </div>

            <br />
            <hr />

            {user.role == "user" ?  
            
            <section>

                <Heading>Substudents:</Heading>
                    <br />
                        <ul>
                        {
                            user.substudent.map((student, index)=> {
                                return <li key={index}>{student.name}</li>
                            })
                        }
                    
                        </ul>
                    <br />
            </section>
            : null}

            {user.role == "teacher" ?  <section>
                <Heading>My Students:</Heading>
                <br />
                <ul>
                {
                    myStudents.map((student, index)=> {
                    return <>
                        <li key={index}>{student.name}
                        
                            <Popover>
                            <PopoverTrigger>
                                <Button className="buttonStyleSmall">delete</Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Confirmation!</PopoverHeader>
                                <PopoverBody>Are you sure you want to remove this student from the teacher's list? <br/>
                                <button onClick={()=>deleteUser(student, index)}> Remove </button>
                                 </PopoverBody>
                            </PopoverContent>
                            </Popover>
                        
                        </li>
                        
                        </>
                    })
                }
            
                </ul>
                <br />
                </section>
            : null}
           
               
                <hr />
                <br />
                <div className="center">
                    <button className="buttonStyle" onClick={showReports}>Show Lesson Reports</button>
                    <button className="buttonStyle" onClick={showTransactions}>Show Transactions</button>
                    <>
      {sizes.map((size) => (
        <button className="buttonStyle"
          onClick={() => handleClick(size)}
          key={size}
          m={4}
        >{`Credit User`}</button>
      ))}

      <Drawer onClose={onClose} isOpen={isOpen} size={size}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerBody>
              <Credit />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
                </div>

                {display.lessonreport == true ? 
               
               <section>
                <div className="center">
                <br />
                <Heading>Lesson Reports:</Heading>
                <br />
                <select
                name="user"
                id="user"
                type="text"
                defaultValue={YearSelected}

                // value={substudents.user}
                onChange={onChangeGetPos}
                required
            >
                <option data-year="2019">2019</option>                   
                <option data-year="2020">2020</option>                   
                <option data-year="2021">2021</option>
                <option data-year="2022">2022</option>    
                <option data-year="2023">2023</option>
                <option data-year="2024">2024</option>  
                <option data-year="2025">2025</option>  
            </select>

            <br />
            <br />
                <button className="buttonStyle" onClick={onChangeHandler} value="0"> Jan </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="1"> Feb </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="2"> Mar </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="3"> Apr </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="4"> May </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="5"> Jun </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="6"> Jul </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="7"> Aug </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="8"> Sep </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="9"> Oct </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="10"> Nov </button>
                <button className="buttonStyle" onClick={onChangeHandler} value="11"> Dec </button>
            </div>
            <br />

                <table className="center">
      <thead>
        <tr>
          <th>Date</th>
          <th>Teacher</th>
          <th>Student</th>
          <th>Lesson Number</th>
          <th>Current Stage</th>
          <th>Description</th>
          <th>Homework</th>
          <th>To be paid</th>
        </tr>
      </thead>

      <tbody>

                {
                    user.lessonreport.map((student, index)=> {
                        return (
                            getDateMonth((student.date)) == monthSelected && getDateYear(student.date) == YearSelected ? 
                            
                            <tr key={index}>
                        <td>{transformDateTimeCreated(student.date)}</td>
                        <td>{student.teacher}</td>
                        <td>{student.name}</td>
                        <td>{student.lessonnumber}</td>
                        <td>{student.currentstage}</td>
                        <td>{student.description}</td>
                        <td>{student.homework}</td>
                        <td>{total += 5}</td>
                    </tr>
                       : null
                    )
                })
            }
              </tbody>
        </table> 
</section>


: null}

{display.transactions == true ? 

<section>

<div className="center">
    <br />
    <Heading>Transactions:</Heading>
    <br />
   <select
    name="user"
    id="user"
    type="text"
    // value={substudents.user}
    defaultValue={YearSelected}
    onChange={onChangeGetPos}
    required
>
    <option data-year="2019">2019</option>                   
    <option  data-year="2020">2020</option>                   
    <option data-year="2021">2021</option>                   
    <option data-year="2022">2022</option>                   
    <option data-year="2023">2023</option>                   
    <option data-year="2024">2024</option>  
    <option data-year="2025">2025</option>  
</select>

<br />
<br />

<button button className="buttonStyle" onClick={onChangeHandler} value="0"> Jan </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="1"> Feb </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="2"> Mar </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="3"> Apr </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="4"> May </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="5"> Jun </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="6"> Jul </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="7"> Aug </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="8"> Sep </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="9"> Oct </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="10"> Nov </button>
<button button className="buttonStyle" onClick={onChangeHandler} value="11"> Dec </button>
</div>
<br />

    <table className="center">
<thead>
<tr>
<th>Date</th>
<th>Credit</th>
<th>Debit</th>
<th>Lesson Descripton</th>
</tr>
</thead>

<tbody>
    {
        user.transactions.map((student, index)=> {
            return (
                getDateMonth((student.date)) == monthSelected && getDateYear(student.date) == YearSelected ? 
           
                <tr key={index}>
                <td>Date: {transformDateTimeCreated(student.date)}</td>
                <td style={{display: "none"}}>Date: {getDateMonth((student.date))}</td>
                <td>Credit: {student.credit}</td>
                <td>Debit: {student.debit}</td>
                <td>{student.descriptionTrans}</td>
            </tr>

            : null
            )   
    })
    }

</tbody>
</table> 
</section>
: 

null}
        
        
                   </div>
        </div>
    )
}

export default Parentedit;
