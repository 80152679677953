import React from 'react';
// import Lessonreportcreate from './Lessonreportcreate';
// import UserService from '../Services/UserService';
// import { AuthContext } from '../Context/AuthContext';
// import Profile from './Profile';
import './Quraan.css'
import './Rwarabicfromprofile.css'

const Rwarabicfromprofile = () => {
    
    let url = window.location.pathname;
    let currentstage = url.substring(url.lastIndexOf("S"), url.length);   
    
    return (
        <div>
            <iframe title="online-activities" className="rwarabicfromprofile-container"
            src={'/Rwarabic/'+currentstage+'/learn.html'} width="100%" height="720"></iframe>                
        </div>
    )
}
export default Rwarabicfromprofile;