export default {
    getSubstudent : ()=>{
        return fetch('/user/substudent')
                .then(response=>{
                    if(response.status !== 401){
                        return response.json().then(data => data);
                    }
                    else
                        return {message : {msgBody : "UnAuthorized",msgError : true}};
                });
    },

    postSubstudent : substudents => {
        return fetch('/user/substudent',{
            method : "post",
            body : JSON.stringify(substudents),
            headers:{
                'Content-Type' : 'application/json'
            }
        }).then(response=>{
            if(response.status !== 401){
                return response.json().then(data => data);
            }
            else
                return {message : {msgBody : "UnAuthorized"}, msgError : true};
        });
    },

    update : substudents =>{
        return fetch('/user/substudent',{
            method : "put",
            body : JSON.stringify(substudents),
            headers : {
                'Content-Type' : 'application/json'
            }
        }).then(res => res.json())
          .then(data => data);
    },

    updateLesson : updateSubstudent =>{
        return fetch('/user/updatesubstudent',{
            method : "put",
            body : JSON.stringify(updateSubstudent),
            headers : {
                'Content-Type' : 'application/json'
            }
        }).then(res => res.json())
          .then(data => data);
    },
}