import React, { useState, useEffect } from 'react';
import UserService from '../Services/UserService';
import LessonreportService from '../Services/LessonreportService';
import SubstudentService from '../Services/SubstudentService';
import './Lessonreportcreate.css'

import {QuraanVerses} from './data';
import {Stages} from './data';

import {
    Button,
    useToast, 
  } from "@chakra-ui/react";

  const verse = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,71,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286]

const Lessonreportcreate = () => {
    // const [verses, setVerses] = useState(7)
    const [getUser, setGetUser] = useState(null);
    let num = -1;
    // const [teachers,setTeachers] = useState([]);
    const [reports,setReports] = useState({id: "", date: new Date(), teacher: "", user: 0, substudent: "", lessonnumber: "", currentstage: "", description:"", homework: "", name: ""});
    const toast = useToast();
    const [updateSubstudent, setUpdateSubstudent] = useState({totallessons: "", currentstage: "", substudent: "", surah: 1, ayahfrom: 1, ayahto: 7});

    const onChange = e => {  
        setReports({...reports, 
            [e.target.date]: e.target.value, 
            [e.target.user]: e.target.value,
            [e.target.name]: e.target.value, 
            [e.target.substudent]: e.target.value, 
            [e.target.lessonnumber]: e.target.value, 
            [e.target.currentstage]: e.target.value, 
            [e.target.description]: e.target.value,
            [e.target.homework]: e.target.value,
        });

            setUpdateSubstudent({
                ...updateSubstudent,
                [e.target.name]: e.target.value,
            })
        
            updateSubstudent.currentstage = reports.currentstage;
            // console.log(reports);
            // console.log('updatesub', updateSubstudent);
        }

    const onChangeGetName = e => {
        const selectedIndex = e.target.options.selectedIndex;
        //creates an attribute that can be defined and sends back the user's name
        reports.name = e.target.options[selectedIndex].getAttribute('data-name');
        updateSubstudent.id = e.target.options[selectedIndex].getAttribute('data-substudentid');
        reports.currentstage = e.target.options[selectedIndex].getAttribute('data-currentstage');
        updateSubstudent.currentstage = e.target.options[selectedIndex].getAttribute('data-currentstage');
        reports.lessonnumber = parseInt(e.target.options[selectedIndex].getAttribute('data-totallessons')) + 1;

        reports.lessontime = reports.lessontime = e.target.options[selectedIndex].getAttribute('data-lessontime');

        updateSubstudent.totallessons = reports.lessonnumber;

        updateSubstudent.surah = reports.surah = e.target.options[selectedIndex].getAttribute('data-surah');


        updateSubstudent.ayahfrom = reports.ayahfrom = e.target.options[selectedIndex].getAttribute('data-ayahfrom');

        updateSubstudent.ayahto = reports.ayahto = e.target.options[selectedIndex].getAttribute('data-ayahto');


        // console.log('update substudent', updateSubstudent);
        setReports({...reports, 
            [e.target.name]: e.target.value, 
        });
        // console.log(reports);
    }
   

    const onSubmit = e =>{
        e.preventDefault();
        LessonreportService.postReport(reports).then(data=>{
        });
        SubstudentService.updateLesson(updateSubstudent).then(data=>{
        });
        console.log('reports', reports);
        // console.log("reports");
        // console.log('update', updateSubstudent);

        
                        toast({
                          title: `Thank you ${getUser.username}`,
                          description: `Lesson report for student created`,
                          status: "success",
                          duration: 7000,
                          isClosable: true,
                        })

    setReports({id: "", date: new Date(), teacher: "", user: 0, substudent: "", lessonnumber: "", currentstage: "", description:"", homework: "", parent: ""});
    }

    useEffect(()=>{
        UserService.getUser().then(data=> {
            getUserFunction(data.users)
            console.log(data.users)

        });
    },[]);

    let getUserFunction = (data) => {
        setGetUser(data);
    }
    if(!getUser) {
        return "no data";
    }
    return(
        <div className="lesson-report-create-container">
            <form onSubmit={onSubmit}>

            <label htmlFor="reports-date">Date: </label><br />

            <input 
                name="date"
                id="reports-date"
                type="text"
                value={reports.date}
                onChange={onChange}
                required
                disabled
            />
            <br />
            <label htmlFor="teacher">Teacher:</label> <br />

            <input
                 name="teacher"
                 id="teacher"
                 type="text"
                 value={getUser.username}
                 required
                 disabled
                 />
                           
 
 <br />
 
<label htmlFor="substudent">Substudent:</label> <br />

            <select
                name="substudent"
                id="substudent"
                type="text"
                value={reports.substudent}
                onChange={onChangeGetName}
                required
            >
                
                <option value="" disabled hidden>Choose here</option>

                {
                    getUser.myStudent.map((student, index)=>{
                        num++;
                        return (
                        <option key={index} 
                        data-substudentid={student._id} 
                        data-name={student.name} 
                        data-totallessons={student.totallessons}
                        data-lessontime={student.lessontime}
                        data-currentstage={student.currentstage}
                        data-surah={student.surah}
                        data-ayahfrom={student.ayahfrom}
                        data-ayahto={student.ayahto}
                        value={student._id}>{num}: {(student.name)}
                        </option>
                        )
                        
                    })
                }
            </select>

            <br />
            <label htmlFor="reports-lessonnumber">Lesson Number:</label> <br />
            <input 
                name="lessonnumber"
                id="reports-lessonnumber"
                type="text"
                value={reports.lessonnumber}
                onChange={onChange} 
                required
                disabled
            />
            <br />

     
            <label htmlFor="reports-currentstage">Current Stage:</label> <br />
            <select name="currentstage"
                id="reports-currentstage"
                type="text"
                value={reports.currentstage}
                onChange={onChange} 
                required
                >
                <option hidden>Choose here</option>
                    {
                        Stages.map((stage, index)=>{
                        return (<option key={index} label={stage.stage}>{stage.value}</option>)
                        })
                    }
                
            </select>
                
            <br />
            <label htmlFor="reports-homework">Description:</label> <br />
            <textarea 
            cols="35"
            rows="4"
             name="description"
             id="reports-description"
             type="text"
             value={reports.description}
             onChange={onChange} 
             required
            >
            </textarea>
            <br />
            <label htmlFor="reports-homework">Homework:</label> <br />
            <textarea 
            cols="35"
            rows="4"
            name="homework"
            id="reports-homework"
            type="text"
            value={reports.homework}
            onChange={onChange}
            required 
            >
            </textarea>
            
            <br />       
            <label htmlFor="surah">Surah:</label> <br />

                <select name="surah"
                id="surah"
                type="number"
                value={updateSubstudent.surah}
                onChange={onChange} 
                required
                >
                <option disabled hidden>Choose here</option>    
                {
               QuraanVerses.map((item, index)=>{
                    return (
                    <option key={index} data-verses={item.verses} label={item.value +". " + item.surah+" ("+(item.verses)+" verses)"}>
                        {item.value}
                    </option>
                    )
               })
           }
                </select>
            <br />       

            <label htmlFor="ayahfrom">Ayah from:</label> <br />

            <select name="ayahfrom"
            id="ayahfrom"
            type="number"
            value={updateSubstudent.ayahfrom}
            onChange={onChange} 
            required
            >
            <option disabled hidden>Choose here</option>    
            {
                    verse.map((num, index)=> {
                        return(<option keys={index}>{num}</option>)
                    })
                }

            </select>
            <br />       


            <label htmlFor="ayahto">Ayah to:</label> <br />

            <select name="ayahto"
            id="ayahto"
            type="number"
            value={updateSubstudent.ayahto}
            onChange={onChange} 
            required
            >
            <option disabled hidden>Choose here</option>    
                {
                    verse.map((num, index)=> {
                        return(<option keys={index}>{num}</option>)
                    })
                }
            </select>
            <br />       

            {/* <button type="submit">Add Report</button>  */}
            <Button type="submit" 
                    leftIcon="add" 
                    variantColor="teal"
            >
                        Add Report
            </Button>
            </form>
            <div className="hidden">
                {reports.id=getUser._id}
                {reports.teacher=getUser.username}
                {reports.parent=getUser._id}
            </div>
                {/* {console.log(reports)} */}

                
            {/* {console.log('lesson time', reports.lessontime)} */}

            {/* <select
                name="chooseSurah"
                id="chooseSurah"
                type="number"
                value={updateSubstudent.surah}
                onChange={onChange} 
                required
                >
           
           </select> */}

            <br />       
            
        </div>
    )
}

export default Lessonreportcreate;