import React from 'react';
import Navbar from './Components/Navbar';
import Login from './Components/Login';
import Home from './Components/Home';
import Resources from './Components/Resources';
import Presources from './Components/Presources';

import Demo from './Components/Demo';
import Tutor from './Components/Tutor';
import About from './Components/About';


// import Todos from './Components/Todos';
import Register from './Components/Register';
import Admin  from './Components/Admin';
import Profile  from './Components/Profile';
import Lessonreport  from './Components/Lessonreport';
import Lessonreportsall  from './Components/Lessonreportsall';
import Lessonreportedit  from './Components/Lessonreportedit';
import Lessonreportcreate  from './Components/Lessonreportcreate';
import Substudent  from './Components/Substudent';
import Substudentedit  from './Components/Substudentedit';
import Mystudentsedit  from './Components/Mystudentsedit';
import Substudentadd  from './Components/Substudentadd';
import Transactions from './Components/Transactions';
import Transactionsall from './Components/Transactionsall';

import Policy from './Components/Policy';

import Book1 from './Components/Book1';
import Book2 from './Components/Book2';

import Parentedit from './Components/Parentedit';

import Teachers from './Components/Teachers';
import Teachermylessons from './Components/Teachermylessons';
import Teachereditmyreports from './Components/Teachereditmyreports';

import Credit from './Components/Credit';

import Live  from './Components/Live';
import Quraan  from './Components/Quraan';
import Rwarabic  from './Components/Rwarabic';
import Rwarabicfromprofile  from './Components/Rwarabicfromprofile';

import PrivateRoute from './hocs/PrivateRoute';
import UnPrivateRoute from './hocs/UnPrivateRoute';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import './App.css';

function App() {
  let path = window.location.pathname;
  var res = path.match(/live/);
  // var b1 = path.match(/pbook1/);
  // var b2 = path.match(/pbook2/);
  // var rwarabicfromprofile = path.match(/rwarabicfromprofile/);

  return (
    <div className="container">
      <Router>
      {res ? null : <Navbar/>}
      {/* {b1 ? null : <Navbar/>}
      {b2 ? null : <Navbar/>} */}
        

        {/* //admin access */}
        <Route exact path="/" component={Home}/>
        <UnPrivateRoute path="/login" component={Login}/>
        <UnPrivateRoute path="/register" component={Register}/>
        <UnPrivateRoute path="/pResources" component={Presources}/> 
        <UnPrivateRoute path="/pBook1" component={Book1}/>
        <UnPrivateRoute path="/pBook2" component={Book2}/>
        <UnPrivateRoute path="/pquraan" component={Quraan}/>
        <UnPrivateRoute path="/prwarabic" component={Rwarabic}/>
        <UnPrivateRoute path="/demo" component={Demo}/>
        <UnPrivateRoute path="/tutor" component={Tutor}/>
        <UnPrivateRoute path="/about" component={About}/>
        <UnPrivateRoute path="/policy" component={Policy}/>

        <PrivateRoute path="/resources" roles={["admin", "teacher", "user"]}  component={Resources}/>
        <PrivateRoute path="/quraan" roles={["admin", "teacher", "user"]}  component={Quraan}/>
        <PrivateRoute path="/rwarabic" roles={["admin", "teacher", "user"]} component={Rwarabic}/>

        <PrivateRoute path="/Book1" roles={["admin", "teacher", "user"]} component={Book1}/>
        <PrivateRoute path="/Book2" roles={["admin", "teacher", "user"]} component={Book2}/>



        <PrivateRoute path="/Credit" roles={["admin"]} component={Credit}/>

        <PrivateRoute path="/admin" roles={["admin"]} component={Admin}/>

        <PrivateRoute path="/teachers" roles={["admin"]} component={Teachers}/>
        <PrivateRoute path="/parentedit" roles={["admin"]} component={Parentedit}/>
        
        <PrivateRoute path="/substudent" roles={["admin"]} component={Substudent}/>

        <PrivateRoute path="/substudentedit" roles={["admin"]} component={Substudentedit}/>

        <PrivateRoute path="/substudentadd" roles={["admin"]} component={Substudentadd}/>
        
        <PrivateRoute path="/lessonreportedit" roles={["admin"]} component={Lessonreportedit}/>

        <PrivateRoute path="/transactionsall" roles={["admin"]} component={Transactionsall}/>
        <PrivateRoute path="/lessonreportsall" roles={["admin"]} component={Lessonreportsall}/>


        {/* //teacher access */}
        <PrivateRoute path="/lessonreportcreate" roles={["admin","teacher"]} component={Lessonreportcreate}/>
        <PrivateRoute path="/teachermylessons" roles={["admin", "teacher"]} component={Teachermylessons}/>
        <PrivateRoute path="/teachereditmyreports" roles={["admin", "teacher"]} component={Teachereditmyreports}/>
        <PrivateRoute path="/mystudentsedit" roles={["admin", "teacher"]} component={Mystudentsedit}/>
  

        {/* //student access */}
        {/* <PrivateRoute path="/todos" roles={["admin", "teacher", "user"]} component={Todos}/> */}
        <PrivateRoute path="/profile" roles={["admin", "teacher", "user"]} component={Profile}/>
        <PrivateRoute path="/live" roles={["admin", "teacher", "user"]} component={Live}/>
        <PrivateRoute path="/rwarabicfromprofile" roles={["admin", "teacher", "user"]} component={Rwarabicfromprofile}/>
        
        <PrivateRoute path="/lessonreport" roles={["admin", "teacher", "user"]} component={Lessonreport}/>
        <PrivateRoute path="/Transactions" roles={["admin", "teacher", "user"]} component={Transactions}/>



      </Router>
    </div>
  );
}

export default App;
